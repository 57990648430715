import * as React from "react";
import {
  IconButton,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import "./input.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function CustomizedInputBase(props) {
  const {
    id,
    label,
    value,
    onChange,
    type,
    startIcon,
    endIcon,
    symbol,
    placeHolder,
    firstIcon,
    name,
    formikOnchange,
    readOnly,
    color,
    error,
    tooltip,
    index,
    addressModel,
    nmfc,
    onBlur,
    numeric,
    customLabelClass,
  } = props;
  const handleChange = (event) => onChange(id, event.target.value, index);
  const handleBlurChange = (event) => onBlur(id, event.target.value, index);

  const is_input_date = type === "date";
  const is_input_number = type === "number";
  const is_input_password = type === "password";

  return (
    <FormControl
      sx={{ m: 1, width: "100%" }}
      className="customInput"
      variant="outlined"
    >
      <FormHelperText
        className={`${
          color === "white" ? "inputLabelWhite" : "inputLabel"
        } ${customLabelClass}`}
      >
        {label}{" "}
        {tooltip ? (
          <Tooltip title={tooltip}>
            <InfoOutlinedIcon style={{ fontSize: "20px" }} />
          </Tooltip>
        ) : (
          ""
        )}
      </FormHelperText>
      {startIcon || type === "password" || type === "date" ? (
        <OutlinedInput
          fullWidth
          name={name}
          type={
            (is_input_date ? "date" : type) ||
            (is_input_number ? "number" : type) ||
            (is_input_password ? "password" : type)
          }
          value={value}
          error={error ? error : false}
          className={color === "white" ? "inputBackgroundWhite" : ""}
          readOnly={readOnly ? readOnly : false}
          onBlur={handleBlurChange}
          onChange={formikOnchange ? formikOnchange : handleChange}
          startAdornment={
            <InputAdornment position="start">
              {symbol ? "$" : ""}
            </InputAdornment>
          }
          endAdornment={
            startIcon ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle icon visibility"
                  edge="end"
                  onClick={addressModel ? addressModel : ""}
                >
                  {startIcon}
                </IconButton>
                {nmfc && (
                  <span className="nmfcError">
                    Only 4 digit allowed for NMFC
                  </span>
                )}
              </InputAdornment>
            ) : (
              ""
            )
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={
            numeric
              ? {
                  "aria-label": "weight",
                  inputMode: "numeric",
                }
              : {
                  "aria-label": "weight",
                }
          }
          placeholder={placeHolder}
          style={{ height: "50px", width: "98%", borderRadius: "8px" }}
        />
      ) : (
        <OutlinedInput
          style={{ height: "50px", width: "98%", borderRadius: "8px" }}
          fullWidth
          type={type}
          name={name}
          value={value}
          onBlur={handleBlurChange}
          error={error ? error : false}
          readOnly={readOnly ? readOnly : false}
          onChange={formikOnchange ? formikOnchange : handleChange}
          startAdornment={
            <InputAdornment position="start">{firstIcon}</InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">{endIcon}</InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={
            numeric
              ? {
                  "aria-label": "weight",
                  inputMode: "numeric",
                }
              : {
                  "aria-label": "weight",
                }
          }
          placeholder={placeHolder}
        />
      )}
    </FormControl>
  );
}
