import React from "react";
import Checkbox from '@mui/material/Checkbox';
import './index.scss'

const Checkboxs = ({ id, name, handleClick, value, disabled }) => {
    return (
        <Checkbox 
            disabled={disabled}
            color="warning"
            id={id}
            name={name}
            checked={value}
            onChange={handleClick}
        />
    );
};

export default Checkboxs;