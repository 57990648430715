import { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Divider, Grid, useMediaQuery } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import "./index.scss";
import { ViewShipmentSidebarData } from "./constant";


const ViewShipmentDrawer = (props) => {
    const [infoOpen, setInfoOpen] = useState(true);
    const matches = useMediaQuery('(max-width:900px)');
    const sideBarDrawer = useSelector(state => state?.sideBarReducer);
    const [menu, setMenu] = useState(props?.name);
    const navigate = useNavigate();
    const params = useParams();

    const sideBarStatus = sideBarDrawer?.sideBarState
    const handleopen = () => {
        setInfoOpen(!infoOpen);
      };
  let styleClass = ( infoOpen && !sideBarStatus ) ? "drawerlist grow" : infoOpen ? "drawerlist grow" : ( !infoOpen && !sideBarStatus ) ? "drawerlist" : "drawerlist growdwon"
  return (
    <div>
    {matches?
    <Grid xs={12} className="view-shipment-tab">
    {ViewShipmentSidebarData?.map((text, index) => (
      <ListItemButton
      className={text.name === menu ? "isActive nav" : "nav"}
      onClick={() => {
        params?.id ? navigate(`${text.layout}/${params?.id}`) : navigate(text.layout);
        setMenu(text.name);
      }}
    >
      <Grid xs={2}>{text.icon}</Grid>
    </ListItemButton>
        ))}
  </Grid>
    :<List className={styleClass} >
      <ListItem>
        <ListItemIcon>
          {infoOpen ? (
            <KeyboardDoubleArrowLeftIcon onClick={() => handleopen()} />
          ) : (
            <KeyboardDoubleArrowRightIcon onClick={() => handleopen()} />
          )}
        </ListItemIcon>
      </ListItem>
      <Divider />
      {ViewShipmentSidebarData?.map((text, index) => (
        <ListItem key={text.name} disablePadding className="listitem">
          <ListItemButton
            className={text.name === menu ? "isActive" : ""}
            onClick={() => {
              params?.id ? navigate(`${text.layout}/${params?.id}`) : navigate(text.layout);
              setMenu(text.name);
            }}
          >
            <ListItemIcon>{text.icon}</ListItemIcon>
            <ListItemText
              primary={text.name}
              disableMargin
              className={infoOpen ? "" : "none"}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>}
  </div>
  )
}

export default ViewShipmentDrawer
