const initialState = {
    trackingData: null,
}

const publicTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TRACKING_DATA":
            return {
                ...state,
                trackingData: action.payload
            }
        default:
            return state
    }
}

export default publicTrackingReducer;