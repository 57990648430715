import React from 'react';
import PropTypes from 'prop-types';

export default function SFLocationIcon({ fill, sx }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill} style={sx}>
    <g id="vuesax_bulk_mouse-circle" data-name="vuesax/bulk/mouse-circle" transform="translate(-620 -636)">
      <g id="mouse-circle">
        <path id="Vector" d="M0,0H24V24H0Z" transform="translate(620 636)" fill="none" opacity="0"/>
        <path id="Vector-2" data-name="Vector" d="M7.95,4.823l-1.63.55a1.505,1.505,0,0,0-.96.96l-.55,1.63a1.516,1.516,0,0,1-2.89-.03L.07,1.983A1.519,1.519,0,0,1,1.97.073l5.96,1.85A1.526,1.526,0,0,1,7.95,4.823Z" transform="translate(633.01 649.017)"/>
        <path id="Vector-3" data-name="Vector" d="M19,9.5A9.5,9.5,0,1,1,9.5,0,9.5,9.5,0,0,1,19,9.5Z" transform="translate(622 638)" opacity="0.4"/>
      </g>
    </g>
  </svg>
  
  
  );
}

SFLocationIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

SFLocationIcon.defaultProps = {
  fill: '#4d4663',
  sx: {},
};
