const initialState = {
  allPresetCommodities: null,
  viewPresetCommodities: null,
  allPresetCommoditiesLength: null,
};

const presetCommoditiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PRESET_COMMODITIES":
      return {
        ...state,
        allPresetCommodities: action.payload.payload,
        allPresetCommoditiesLength: action.payload.countOfData,
      };
    case "GET_VIEW_PRESET_COMMODITIES":
      return {
        ...state,
        viewPresetCommodities: action.payload,
      };
    default:
      return state;
  }
};

export default presetCommoditiesReducer;
