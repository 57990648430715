const initialState = {
    sideBarState: true,
}

const sideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SIDEBAR_DETAILS":
            return {
                ...state,
                sideBarState: action.payload,
            }
        default:
            return state
    }
}

export default sideBarReducer;