import React from "react";
import {
  Box,
  Card,
  Divider,
  Grid,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { History, Dehaze, ImportContacts } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Checkboxs from "../../components/CheckBox";
import CustomButton from "../../components/Button";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.scss";
import AddressShipmentHooks from "./AddressShipmentHooks";
import ViewShipmentDrawer from "../../components/ViewShipmentDrawer";
import BackToShipment from "../../components/BackToShipment";
import DialogBox from "../../components/dialog-box/dialogBox";
import { snakeToCamel } from "../../utils/helper";
import CircularLoader from "../../components/Loader";
const shipmentDrawerWidth = 240;

export default function ViewShipmentDetails(props) {
  const {
    originAddress,
    destinationAddress,
    viewShipmentDetails,
    open,
    setOpen,
    dialogData,
    handleTransist,
    loadingData,
    density,
    renderInput,
    handleClose,
    params,
    checkDeliveryHandleChanges,
    checkHandleChanges,
    handleChangeInput,
    matches,
    radiusMatches,
  } = AddressShipmentHooks();

  return (
    <>
      {loadingData ? (
        <CircularLoader />
      ) : (
        <div style={{ width: "100%" }}>
          {matches ? (
            <ViewShipmentDrawer
              name={"Shipment"}
              item={{ shipment_id: params?.id }}
              shipment_id={params?.id}
            />
          ) : (
            ""
          )}
          <div
            className={!radiusMatches ? "background-component" : ""}
            style={{ backgroundColor: "#fff", position: "relative" }}
          >
            <Box
              sx={{
                marginLeft: { xs: "0%", md: "3%", xl: "2%" },
                marginTop: { xs: "20px", md: "20px", xl: "20px" },
              }}
            >
              <Box sx={{ flexGrow: 1, width: "100%" }}>
                <Grid
                  container
                  spacing={2}
                  className="view-shipment-title"
                  sx={{ width: "100%" }}
                >
                  <Grid item xs={12} xl={12}>
                    <Typography variant={"h6"} style={{ fontWeight: "800" }}>
                      View Shipment
                    </Typography>
                    <Typography className="accordion-label">
                      View selected shipment details
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className="divider_viewshipment" />
                <Box>
                  <Box
                    component="nav"
                    sx={{
                      width: { sm: shipmentDrawerWidth },
                      flexShrink: { sm: 0 },
                    }}
                    aria-label="mailbox folders"
                  >
                    <BackToShipment />
                  </Box>
                </Box>
                {matches ? (
                  <Box sx={{ display: "flex" }} className="info-box-mobile">
                    <CssBaseline />
                    <Box
                      component="main"
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.default",
                      }}
                    >
                      <Box className="shipmentsDetails">
                        <div className="miles-class">
                          <Typography variant="subtitle2" gutterBottom>
                            Total Miles
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            value={viewShipmentDetails?.miles}
                            variant="outlined"
                            disabled
                            inputProps={{
                              style: {
                                padding: "8px 10px",
                              },
                            }}
                          />
                        </div>
                        <div className="miles-class">
                          <CustomButton
                            type={"add"}
                            label={"Fetch Miles"}
                            FontSize={"10px"}
                            LineHeight={"1"}
                            Padding={"0px"}
                            width={"100%"}
                          />
                        </div>
                        <div className="transit-class">
                          <CustomButton
                            type={"transit"}
                            icon={<Dehaze />}
                            label={"Transit Details"}
                            buttonOnClick={() => handleTransist()}
                            LineHeight={"1"}
                            FontSize={"10px"}
                            Padding={"8px"}
                            width={"100%"}
                          />
                        </div>
                      </Box>
                      <Grid
                        container
                        spacing={2}
                        sx={{ width: "100%", marginLeft: "0px" }}
                      >
                        <Grid style={{ backgroundColor: "#F1F0F5" }}>
                          <Grid
                            container
                            spacing={2}
                            style={{ width: "100%", margin: "0 auto" }}
                          >
                            <Card
                              item
                              xs={12}
                              lg={6}
                              style={{
                                marginBottom: "25px",
                                backgroundColor: "#fff",
                                padding: "12px 24px",
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "20px" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  xl={3.5}
                                  style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Origin
                                  </Typography>
                                  <Link
                                    to={"/address-book"}
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                      alignItems: "center",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <ImportContacts
                                      style={{ color: "#009AEB" }}
                                    />
                                    <Typography
                                      style={{
                                        fontWeight: "500",
                                        color: "#009AEB",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Address Book
                                    </Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                              <Grid
                                spacing={2}
                                sx={{ width: "100%" }}
                                style={{ marginBottom: "20px" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  lg={11.5}
                                  style={{ padding: "0px", width: "100%" }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: "25px",
                                      backgroundColor: "#FAFAFA",
                                    }}
                                  >
                                    <CardContent>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                          {viewShipmentDetails?.origin?.city +
                                            ","}{" "}
                                          {viewShipmentDetails?.origin?.state +
                                            ","}{" "}
                                          {viewShipmentDetails?.origin
                                            ?.zipcode + ","}{" "}
                                          US
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                style={{
                                  width: "100%",
                                  margin: "0 auto",
                                  paddingRight: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  lg={11.5}
                                  style={{
                                    marginBottom: "20px",
                                    padding: "0px",
                                  }}
                                >
                                  <Typography className="accordion-label">
                                    ADDRESS DETAILS
                                  </Typography>
                                  {renderInput(
                                    "text",
                                    "originName",
                                    "Name* ",
                                    originAddress.originName,
                                    ""
                                  )}
                                  {renderInput(
                                    "text",
                                    "addressLine1",
                                    "Address Line 1",
                                    originAddress.addressLine1,
                                    ""
                                  )}
                                  {renderInput(
                                    "text",
                                    "addressLine2",
                                    "Address Line 2",
                                    originAddress.addressLine2,
                                    ""
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  lg={11.5}
                                  style={{
                                    marginBottom: "20px",
                                    padding: "0px",
                                  }}
                                >
                                  <Typography className="accordion-label">
                                    PICKUP DETAILS
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "Pickup Location Type",
                                        "Pickup Location Type",
                                        originAddress.LocationType
                                          ? snakeToCamel(
                                              originAddress.LocationType
                                            )
                                          : "",
                                        ""
                                      )}
                                      {/* {renderSelect(
                                    "pickUpLocation",
                                    originAddress.LocationType,
                                    PickUpLocation,
                                    "",
                                    "Pickup Location Type"
                                  )} */}
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "Pickup Building Area",
                                        "Pickup Building Area",
                                        originAddress.pickupArea
                                          ? snakeToCamel(
                                              originAddress.pickupArea
                                            )
                                          : "",
                                        ""
                                      )}
                                      {/* {renderSelect(
                                    "pickUpBuilding",
                                    originAddress.pickupArea,
                                    PickUpBuilding,
                                    "",
                                    "Pickup Building Area"
                                  )} */}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "pickupStartTime",
                                        "Pickup Start Time",
                                        originAddress.pickupStartTime,
                                        <History />
                                      )}
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "pickupEndTime",
                                        "Pickup End Time",
                                        originAddress.pickupEndTime,
                                        <History />
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={11.5}
                                  style={{
                                    marginBottom: "20px",
                                    padding: "0px",
                                  }}
                                >
                                  <Typography className="accordion-label">
                                    CONTACT DETAILS
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "coordinator",
                                        "Shipping Coordinator",
                                        originAddress?.coordinator
                                      )}
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "email",
                                        "Contact Email",
                                        originAddress.email
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={8}>
                                      {renderInput(
                                        "text",
                                        "dialCode",
                                        "United States Dial Code: 1",
                                        originAddress.dialCode
                                      )}
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                      {renderInput(
                                        "text",
                                        "ext",
                                        "Ext.",
                                        originAddress.ext
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkboxs
                                      name={"INCLUDE PICKUP TIMES ON BOL"}
                                      handleClick={checkHandleChanges}
                                    />
                                    {"INCLUDE PICKUP TIMES ON BOL"}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>

                            <Card
                              item
                              xs={12}
                              lg={6}
                              style={{
                                backgroundColor: "#fff",
                                padding: "12px 24px",
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "20px" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={7}
                                  xl={4.5}
                                  style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Destination
                                  </Typography>
                                  <Link
                                    to={"/address-book"}
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                      alignItems: "center",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <ImportContacts
                                      style={{ color: "#009AEB" }}
                                    />
                                    <Typography
                                      style={{
                                        fontWeight: "500",
                                        color: "#009AEB",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Address Book
                                    </Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                              <Grid
                                spacing={2}
                                sx={{ width: "100%" }}
                                style={{ marginBottom: "20px" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  lg={12}
                                  style={{ padding: "0px", width: "100%" }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: "25px",
                                      backgroundColor: "#FAFAFA",
                                    }}
                                  >
                                    <CardContent>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                          {viewShipmentDetails?.destination
                                            ?.city + ","}{" "}
                                          {viewShipmentDetails?.destination
                                            ?.state + ","}{" "}
                                          {viewShipmentDetails?.destination
                                            ?.zipcode + ","}{" "}
                                          US
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                style={{
                                  width: "100%",
                                  margin: "0 auto",
                                  paddingRight: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  lg={12}
                                  style={{
                                    marginBottom: "20px",
                                    padding: "0px",
                                  }}
                                >
                                  <Typography className="accordion-label">
                                    ADDRESS DETAILS
                                  </Typography>
                                  {renderInput(
                                    "text",
                                    "destinationName",
                                    "Name*",
                                    destinationAddress.destinationName,
                                    ""
                                  )}
                                  {renderInput(
                                    "text",
                                    "destinationAddressLine1",
                                    "Address Line 1",
                                    destinationAddress.destinationAddressLine1,
                                    ""
                                  )}
                                  {renderInput(
                                    "text",
                                    "destinationAddressLine2",
                                    "Address Line 2",
                                    destinationAddress.destinationAddressLine2,
                                    ""
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  lg={12}
                                  style={{
                                    marginBottom: "20px",
                                    padding: "0px",
                                  }}
                                >
                                  <Typography className="accordion-label">
                                    DELIVERY DETAILS
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "Delivery Location Type",
                                        "Delivery Location Type",
                                        destinationAddress.destinationLocationType
                                          ? snakeToCamel(
                                              destinationAddress.destinationLocationType
                                            )
                                          : "",
                                        ""
                                      )}
                                      {/* {renderSelect(
                                    "deliveryLocation",
                                    destinationAddress.destinationLocationType,
                                    PickUpLocation,
                                    "",
                                    "Delivery Location Type"
                                  )} */}
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "Delivery Building Area",
                                        "Delivery Building Area",
                                        destinationAddress.destinationPickupArea
                                          ? snakeToCamel(
                                              destinationAddress.destinationPickupArea
                                            )
                                          : "",
                                        ""
                                      )}
                                      {/* {renderSelect(
                                    "deliveryBuilding",
                                    destinationAddress.destinationPickupArea,
                                    PickUpBuilding,
                                    "",
                                    "Delivery Building Area"
                                  )} */}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "destinationStartTime",
                                        "Delivery Start Time",
                                        destinationAddress.destinationStartTime,
                                        <History />
                                      )}
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "destinationEndTime",
                                        "Delivery End Time",
                                        destinationAddress.destinationEndTime,
                                        <History />
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={12}
                                  style={{
                                    marginBottom: "20px",
                                    padding: "0px",
                                  }}
                                >
                                  <Typography className="accordion-label">
                                    CONTACT DETAILS
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "destinationCoordinator",
                                        "Receiving Contact",
                                        destinationAddress?.destinationCoordinator
                                      )}
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      {renderInput(
                                        "text",
                                        "destinationEmail",
                                        "Contact Email",
                                        destinationAddress.destinationEmail
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={8}>
                                      {renderInput(
                                        "text",
                                        "destinationDialCode",
                                        "United States Dial Code: 1",
                                        destinationAddress.destinationDialCode
                                      )}
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                      {renderInput(
                                        "text",
                                        "ext",
                                        "Ext.",
                                        destinationAddress.ext
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkboxs
                                      name={"INCLUDE DELIVERY TIMES ON BOL"}
                                      handleClick={checkDeliveryHandleChanges}
                                    />
                                    {"INCLUDE DELIVERY TIMES ON BOL"}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                        <div
                          style={
                            radiusMatches
                              ? { width: "100%" }
                              : { width: "100%", backgroundColor: "#F1F0F5" }
                          }
                        >
                          {viewShipmentDetails?.handlingUnits &&
                            viewShipmentDetails?.handlingUnits.map(
                              (item, index) => (
                                <Card
                                  style={{
                                    margin: "20px 0px",
                                    backgroundColor: "#FAFAFA",
                                  }}
                                >
                                  <CardContent>
                                    <Grid
                                      style={{
                                        display: "flex",
                                        marginBottom: "15px",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <Grid item xs={12} md={6} lg={2} xl={3}>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {"Handling Type"} -{" "}
                                          {item?.handling_type}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} md={6} lg={3} xl={3}>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {"Commodity"} -{" "}
                                          {item?.commodities.map(
                                            (j) => j?.nmfc_description
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Divider
                                      style={{
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      }}
                                    />
                                    <Grid
                                      container
                                      spacing={2}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Grid item xs={6} sm={4} md={3} lg={1}>
                                        <Typography className="accordion-label">
                                          Length (In)
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.length}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={1}
                                        align={radiusMatches ? "right" : "left"}
                                      >
                                        <Typography
                                          className={"accordion-label"}
                                        >
                                          Width (In)
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.width}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                        <Typography className="accordion-label">
                                          Height (In)
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.height}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={1.2}
                                        align={radiusMatches ? "right" : "left"}
                                      >
                                        <Typography className="accordion-label">
                                          Handling Units
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.num_skids}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.6}>
                                        <Typography className="accordion-label">
                                          Total Weight (Lbs)
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities.map(
                                            (j) => j?.weight
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={1}
                                        align={radiusMatches ? "right" : "left"}
                                      >
                                        <Typography className="accordion-label">
                                          Total Pieces
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities.map(
                                            (u) => u?.number_of_units
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                        <Typography className="accordion-label">
                                          Density
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {density(
                                            item?.commodities.map(
                                              (u) => u?.weight
                                            ),
                                            item?.height,
                                            item?.width,
                                            item?.length,
                                            item?.num_skids
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={1.2}
                                        align={radiusMatches ? "right" : "left"}
                                      >
                                        <Typography className="accordion-label">
                                          Freight Class
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities.map(
                                            (u) => u?.freight_class
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.3}>
                                        <Typography className="accordion-label">
                                          Cust Order/PO#
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities[0]?.po_number}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={1.2}
                                        align={radiusMatches ? "right" : "left"}
                                      >
                                        <Typography className="accordion-label">
                                          Shipper info
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities.map(
                                            (u) => u?.shipper_number
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              )
                            )}
                        </div>
                        <Grid
                          container
                          spacing={2}
                          style={
                            radiusMatches
                              ? { margin: "0px 12px 25px" }
                              : {
                                  padding: "0px 0px 25px 12px",
                                  backgroundColor: "#F1F0F5",
                                }
                          }
                        >
                          <Grid item xs={12} lg={12} style={{ padding: "0px" }}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: 700 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {
                                "Notes/Instructions to appear on Bill of Lading:"
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={12} style={{ padding: "0px" }}>
                            <TextareaAutosize
                              minRows={7}
                              value={
                                viewShipmentDetails?.shipment?.customer_notes
                              }
                              style={{
                                width: "100%",
                                fontSize: "18px",
                                backgroundColor: "#fff",
                              }}
                              onChange={(e) =>
                                handleChangeInput(
                                  "customer_notes",
                                  e.target.value
                                )
                              }
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex" }} className="info-box">
                    <CssBaseline />
                    <Box
                      component="main"
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.default",
                        p: 3,
                        width: { sm: `calc(100% - ${shipmentDrawerWidth}px)` },
                        marginLeft: "112px",
                      }}
                    >
                      {!matches ? (
                        <ViewShipmentDrawer
                          name={"Shipment"}
                          item={{ shipment_id: params?.id }}
                          shipment_id={params?.id}
                        />
                      ) : (
                        ""
                      )}
                      <Grid
                        container
                        spacing={2}
                        sx={{ width: "100%" }}
                        style={{ marginBottom: "25px" }}
                      >
                        {/* <TenderShipmentToCarrier/> */}
                        <Grid className="shipmentsHeading">
                          {/* <h5>Shipments</h5> */}
                        </Grid>
                        <Box className="shipmentsDetails">
                          <Grid className={"milesGrid"}>
                            <p style={{ marginBottom: "0px" }}>Total Miles</p>
                            <div className={"miles"}>
                              <div className={"milesInput"}>
                                <TextField
                                  id="outlined-basic"
                                  value={viewShipmentDetails?.miles}
                                  variant="outlined"
                                  disabled
                                  inputProps={{
                                    style: {
                                      padding: "8px 10px",
                                    },
                                  }}
                                />
                              </div>
                              <CustomButton
                                type={"add"}
                                label={"Fetch Miles"}
                              />
                            </div>
                          </Grid>
                          <Grid className="transistDetailsButton">
                            <CustomButton
                              type={"transit"}
                              icon={<Dehaze />}
                              label={"Transit Details"}
                              buttonOnClick={() => handleTransist()}
                              LineHeight={"1"}
                              FontSize={"12px"}
                              Padding={"10px"}
                            />
                          </Grid>
                        </Box>
                        <Card
                          style={{
                            marginBottom: "25px",
                            backgroundColor: "#FAFAFA",
                            border: "none",
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginBottom: "20px" }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    xl={3.5}
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Origin
                                    </Typography>
                                    <Link
                                      to={"/address-book"}
                                      style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <ImportContacts
                                        style={{ color: "#009AEB" }}
                                      />
                                      <Typography
                                        style={{
                                          fontWeight: "500",
                                          color: "#009AEB",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Address Book
                                      </Typography>
                                    </Link>
                                  </Grid>
                                </Grid>
                                <Grid
                                  spacing={2}
                                  sx={{ width: "100%" }}
                                  style={{ marginBottom: "20px" }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    lg={11.5}
                                    style={{ padding: "0px", width: "100%" }}
                                  >
                                    <Card
                                      style={{
                                        marginBottom: "25px",
                                        backgroundColor: "#FAFAFA",
                                      }}
                                    >
                                      <CardContent>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} lg={12}>
                                            {viewShipmentDetails?.origin?.city +
                                              ","}{" "}
                                            {viewShipmentDetails?.origin
                                              ?.state + ","}{" "}
                                            {viewShipmentDetails?.origin
                                              ?.zipcode + ","}{" "}
                                            US
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={11.5}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Typography className="accordion-label">
                                      ADDRESS DETAILS
                                    </Typography>
                                    {renderInput(
                                      "text",
                                      "originName",
                                      "Name* ",
                                      originAddress.originName,
                                      ""
                                    )}
                                    {renderInput(
                                      "text",
                                      "addressLine1",
                                      "Address Line 1",
                                      originAddress.addressLine1,
                                      ""
                                    )}
                                    {renderInput(
                                      "text",
                                      "addressLine2",
                                      "Address Line 2",
                                      originAddress.addressLine2,
                                      ""
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={11.5}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Typography className="accordion-label">
                                      PICKUP DETAILS
                                    </Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "Pickup Location Type",
                                          "Pickup Location Type",
                                          originAddress.LocationType
                                            ? snakeToCamel(
                                                originAddress.LocationType
                                              )
                                            : "",
                                          ""
                                        )}
                                        {/* {renderSelect(
                                  "pickUpLocation",
                                  originAddress.LocationType,
                                  PickUpLocation,
                                  "",
                                  "Pickup Location Type"
                                )} */}
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "Pickup Building Area",
                                          "Pickup Building Area",
                                          originAddress.pickupArea
                                            ? snakeToCamel(
                                                originAddress.pickupArea
                                              )
                                            : "",
                                          ""
                                        )}
                                        {/* {renderSelect(
                                  "pickUpBuilding",
                                  originAddress.pickupArea,
                                  PickUpBuilding,
                                  "",
                                  "Pickup Building Area"
                                )} */}
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "pickupStartTime",
                                          "Pickup Start Time",
                                          originAddress.pickupStartTime,
                                          <History />
                                        )}
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "pickupEndTime",
                                          "Pickup End Time",
                                          originAddress.pickupEndTime,
                                          <History />
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    lg={11.5}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Typography className="accordion-label">
                                      CONTACT DETAILS
                                    </Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "coordinator",
                                          "Shipping Coordinator",
                                          originAddress?.coordinator
                                        )}
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "email",
                                          "Contact Email",
                                          originAddress.email
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={8}>
                                        {renderInput(
                                          "text",
                                          "dialCode",
                                          "United States Dial Code: 1",
                                          originAddress.dialCode
                                        )}
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        {renderInput(
                                          "text",
                                          "ext",
                                          "Ext.",
                                          originAddress.ext
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkboxs
                                        disabled={true}
                                        value={
                                          viewShipmentDetails?.shipment
                                            ?.is_times_included_origin
                                        }
                                        name={"INCLUDE PICKUP TIMES ON BOL"}
                                        handleClick={checkHandleChanges}
                                      />
                                      {"INCLUDE PICKUP TIMES ON BOL"}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginBottom: "20px" }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={7}
                                    xl={4.5}
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Destination
                                    </Typography>
                                    <Link
                                      to={"/address-book"}
                                      style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <ImportContacts
                                        style={{ color: "#009AEB" }}
                                      />
                                      <Typography
                                        style={{
                                          fontWeight: "500",
                                          color: "#009AEB",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Address Book
                                      </Typography>
                                    </Link>
                                  </Grid>
                                </Grid>
                                <Grid
                                  spacing={2}
                                  sx={{ width: "100%" }}
                                  style={{ marginBottom: "20px" }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    lg={12}
                                    style={{ padding: "0px", width: "100%" }}
                                  >
                                    <Card
                                      style={{
                                        marginBottom: "25px",
                                        backgroundColor: "#FAFAFA",
                                      }}
                                    >
                                      <CardContent>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} lg={12}>
                                            {viewShipmentDetails?.destination
                                              ?.city + ","}{" "}
                                            {viewShipmentDetails?.destination
                                              ?.state + ","}{" "}
                                            {viewShipmentDetails?.destination
                                              ?.zipcode + ","}{" "}
                                            US
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={12}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Typography className="accordion-label">
                                      ADDRESS DETAILS
                                    </Typography>
                                    {renderInput(
                                      "text",
                                      "destinationName",
                                      "Name*",
                                      destinationAddress.destinationName,
                                      ""
                                    )}
                                    {renderInput(
                                      "text",
                                      "destinationAddressLine1",
                                      "Address Line 1",
                                      destinationAddress.destinationAddressLine1,
                                      ""
                                    )}
                                    {renderInput(
                                      "text",
                                      "destinationAddressLine2",
                                      "Address Line 2",
                                      destinationAddress.destinationAddressLine2,
                                      ""
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={12}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Typography className="accordion-label">
                                      DELIVERY DETAILS
                                    </Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "Delivery Location Type",
                                          "Delivery Location Type",
                                          destinationAddress.destinationLocationType
                                            ? snakeToCamel(
                                                destinationAddress.destinationLocationType
                                              )
                                            : "",
                                          ""
                                        )}
                                        {/* {renderSelect(
                                  "deliveryLocation",
                                  destinationAddress.destinationLocationType,
                                  PickUpLocation,
                                  "",
                                  "Delivery Location Type"
                                )} */}
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "Delivery Building Area",
                                          "Delivery Building Area",
                                          destinationAddress.destinationPickupArea
                                            ? snakeToCamel(
                                                destinationAddress.destinationPickupArea
                                              )
                                            : "",
                                          ""
                                        )}
                                        {/* {renderSelect(
                                  "deliveryBuilding",
                                  destinationAddress.destinationPickupArea,
                                  PickUpBuilding,
                                  "",
                                  "Delivery Building Area"
                                )} */}
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "destinationStartTime",
                                          "Delivery Start Time",
                                          destinationAddress.destinationStartTime,
                                          <History />
                                        )}
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "destinationEndTime",
                                          "Delivery End Time",
                                          destinationAddress.destinationEndTime,
                                          <History />
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    lg={12}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Typography className="accordion-label">
                                      CONTACT DETAILS
                                    </Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "destinationCoordinator",
                                          "Receiving Contact",
                                          destinationAddress?.destinationCoordinator
                                        )}
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        {renderInput(
                                          "text",
                                          "destinationEmail",
                                          "Contact Email",
                                          destinationAddress.destinationEmail
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={8}>
                                        {renderInput(
                                          "text",
                                          "destinationDialCode",
                                          "United States Dial Code: 1",
                                          destinationAddress.destinationDialCode
                                        )}
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        {renderInput(
                                          "text",
                                          "ext",
                                          "Ext.",
                                          destinationAddress.ext
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkboxs
                                        disabled={true}
                                        value={
                                          viewShipmentDetails?.shipment
                                            ?.is_times_included_dest
                                        }
                                        name={"INCLUDE DELIVERY TIMES ON BOL"}
                                        handleClick={checkDeliveryHandleChanges}
                                      />
                                      {"INCLUDE DELIVERY TIMES ON BOL"}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                        <div style={{ width: "100%" }}>
                          {viewShipmentDetails?.handlingUnits &&
                            viewShipmentDetails?.handlingUnits.map(
                              (item, index) => (
                                <Card style={{ margin: "20px 10px" }}>
                                  <CardContent>
                                    <Grid
                                      style={{
                                        display: "flex",
                                        marginBottom: "15px",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <Grid item xs={12} md={6} lg={2} xl={3}>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {"Handling Type"} -{" "}
                                          {item?.handling_type}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} md={6} lg={3} xl={3}>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {"Commodity"} -{" "}
                                          {item?.commodities.map(
                                            (j) => j?.nmfc_description
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Divider
                                      style={{
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      }}
                                    />
                                    <Grid
                                      container
                                      spacing={2}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Grid item xs={6} sm={4} md={3} lg={1}>
                                        <Typography className="accordion-label stats-accordian">
                                          Length (In)
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.length}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1}>
                                        <Typography className="accordion-label stats-accordian">
                                          Width (In)
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.width}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                        <Typography className="accordion-label stats-accordian">
                                          Height (In)
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.height}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                        <Typography className="accordion-label stats-accordian">
                                          Handling Units
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.num_skids}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.6}>
                                        <Typography className="accordion-label stats-accordian">
                                          Total Weight (Lbs)
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities.map(
                                            (j) => j?.weight
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1}>
                                        <Typography className="accordion-label stats-accordian">
                                          Total Pieces
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities.map(
                                            (u) => u?.number_of_units
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                        <Typography className="accordion-label stats-accordian">
                                          Density
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {density(
                                            item?.commodities.map(
                                              (u) => u?.weight
                                            ),
                                            item?.height,
                                            item?.width,
                                            item?.length,
                                            item?.num_skids
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                        <Typography className="accordion-label stats-accordian">
                                          Freight Class
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities.map(
                                            (u) => u?.freight_class
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.3}>
                                        <Typography className="accordion-label stats-accordian">
                                          Cust Order/PO#
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities[0]?.po_number}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                        <Typography className="accordion-label stats-accordian">
                                          Shipper info
                                        </Typography>
                                        <Typography
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.commodities.map(
                                            (u) => u?.shipper_number
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              )
                            )}
                        </div>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginBottom: "25px" }}
                        >
                          <Grid item xs={12} lg={12}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: 700 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {
                                "Notes/Instructions to appear on Bill of Lading:"
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <TextareaAutosize
                              minRows={7}
                              value={
                                viewShipmentDetails?.shipment?.customer_notes
                              }
                              style={{ width: "100%", fontSize: "18px" }}
                              onChange={(e) =>
                                handleChangeInput(
                                  "customer_notes",
                                  e.target.value
                                )
                              }
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <DialogBox
              open={open}
              onClose={handleClose}
              data={dialogData}
              setOpen={setOpen}
            />
          </div>
        </div>
      )}
    </>
  );
}
