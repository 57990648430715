import * as React from 'react';
import {Link} from "react-router-dom";
import {Grid} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {ArrowBackIos} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
const BackToShipment = () => {
  return (
        <div>
            <Toolbar>
                <Grid
                    container
                    spacing={2}
                    className="back-to-top"
                    style={{ paddingBottom: "20px" }}
                >
                    <Grid item xs={12} xl={12}>
                        <Link
                            to={"/"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                                color: "#009AEB",
                                textDecoration: "none",
                            }}
                        >
                            <ArrowBackIos fontSize={"15px"} />
                            <Typography style={{ fontWeight: "600" }}>
                                Back to Shipments
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Toolbar>
        </div>
  )
}
export default BackToShipment
