const initialState = {
  walletHistory: null,
};

const walletHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WALLET_HISTORY":
      return {
        ...state,
        walletHistory: action.payload,
      };
    default:
      return state;
  }
};

export default walletHistoryReducer;
