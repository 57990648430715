const initialState = {
  getCarriersData: null,
  postRateOfCarrier: null,
  postRateOffailCarrier: null,
  postRateOffailCarrierMessage: null,
  postTransistData: null,
  acceptQuoteData: null,
  quoteInsurance: null,
  quoteDetailsAllData: null,
  getRateOfCarrierFail: null,
};

const quoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_QUOTE_DETAILS":
      return {
        ...state,
        getCarriersData: action.payload,
        postRateOffailCarrier: null,
        postRateOfCarrier: null,
        getRateOfCarrierFail: null,
      };
    case "POST_RATE_OF_CARRIER":
      return {
        ...state,
        postRateOfCarrier:
          state.postRateOfCarrier == null
            ? [action.payload]
            : [...state.postRateOfCarrier, action.payload],
      };
    case "POST_RATE_OF_FAIL_CARRIER":
      return {
        ...state,
        postRateOffailCarrier:
          state.postRateOffailCarrier === null
            ? [action.payload]
            : [...state.postRateOffailCarrier, action.payload],
      };
    case "POST_RATE_OF_FAIL_CARRIER_MESSAGE":
      return {
        ...state,
        postRateOffailCarrierMessage: action.payload,
      };
    case "POST_TRANSIST_DATA":
      return {
        ...state,
        postTransistData: action.payload,
      };
    case "POST_QUOTE_ACCEPT":
      return {
        ...state,
        acceptQuoteData: action.payload,
        quoteInsurance: null,
        postRateOffailCarrier: null,
        postRateOfCarrier: null,
        getCarriersData: null,
        getRateOfCarrierFail: null,
      };
    case "POST_QUOTE_INSURANCE":
      return {
        ...state,
        quoteInsurance: action.payload,
      };
    case "POST_CLEAR_CARRIER_DATA":
      return {
        ...state,
        quoteDetailsAllData: action.payload,
      };
    case "POST_RERENDER":
      return {
        ...state,
        postRateOfCarrier: action.payload,
      };
    case "GET_QUOTE_DETAILS_ALL":
      return {
        ...state,
        quoteDetailsAllData: action.payload,
        // postRateOfCarrier:action.payload
      };
    case "GET_RATE_OF_CARRIER_FAIL":
      return {
        ...state,
        getRateOfCarrierFail:
          state.getRateOfCarrierFail === null
            ? [action.payload]
            : [...state.getRateOfCarrierFail, action.payload],
      };
    case "CLEAR_ALL_DATA":
      return initialState;
    default:
      return state;
  }
};

export default quoteReducer;
