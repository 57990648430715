import React from "react";

function LTLDefault() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g
        data-name="vuesax/bulk/task-square"
        transform="translate(-494 -317.97)"
      >
        <g>
          <path
            d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81v8.38C0 17.83 2.17 20 5.81 20h8.38c3.64 0 5.81-2.17 5.81-5.81V5.81C20 2.17 17.83 0 14.19 0z"
            opacity="0.4"
            transform="translate(494 317.97)"
          ></path>
          <path
            d="M6.75.75A.749.749 0 016 1.5H.75A.755.755 0 010 .75.755.755 0 01.75 0H6a.749.749 0 01.75.75z"
            data-name="Vector"
            transform="translate(503.56 324.09)"
          ></path>
          <path
            d="M4.285 1.277l-2.25 2.25a.742.742 0 01-.53.22.725.725 0 01-.53-.22l-.75-.75a.737.737 0 010-1.06.754.754 0 011.06 0l.22.22L3.225.218a.75.75 0 111.06 1.06z"
            data-name="Vector"
            transform="translate(497.685 322.592)"
          ></path>
          <path
            d="M6.75.75A.749.749 0 016 1.5H.75A.755.755 0 010 .75.755.755 0 01.75 0H6a.749.749 0 01.75.75z"
            data-name="Vector"
            transform="translate(503.56 331.09)"
          ></path>
          <path
            d="M4.285 1.277l-2.25 2.25a.742.742 0 01-.53.22.725.725 0 01-.53-.22l-.75-.75a.737.737 0 010-1.06.754.754 0 011.06 0l.22.22L3.225.218a.75.75 0 111.06 1.06z"
            data-name="Vector"
            transform="translate(497.685 329.592)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default LTLDefault;
