import { useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { postEnterShipmentDetails } from '../QuoteAccepted/action';

export default function ViewQuoteHook(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate()  
    const matches = useMediaQuery('(max-width:900px)');
    const radiusMatches = useMediaQuery('(max-width:600px)');
    const params = useParams(); 
    const [loadingData, setLoadingData] = useState(true);
    
    useEffect(()=> {
      (async()=>{
        if(!params?.id){
          navigate('/')
      }
        setLoadingData(true)
        await dispatch(postEnterShipmentDetails({shipment_id:params?.id}))
        setLoadingData(false)
      })()
    },[])

    return {
      loadingData,
      params,
      matches,
      radiusMatches
    };
}
