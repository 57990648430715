const initialState = {
    EnterShipmentDetails: null,
    ErrorMessage : "No Data Found"
}

const AcceptQuoteReducer = (state = initialState, action) => {
    switch (action.type) {
        case "POST_ENTER_SHIPMENT":
            return {
                ...state,
                EnterShipmentDetails: action.payload
            }
        case "ERROR_ENTER_SHIPMENT":
            return {
                ...state,
                ErrorMessage: action.payload
            }
        default:
            return state
    }
}

export default AcceptQuoteReducer;