const initialState = {
    addressbook: null
}

const addressBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ADDRESSBOOK": 
            return {
                ...state,
                addressbook: action.payload
            }
        case "GET_SEARCH_ADDRESS":
            return {
                addressbook: action.payload
            }
        default:
            return state
    }
}

export default addressBookReducer;
