const initialState = {
    currentBalance: null,
    allshimpents: [],
    viewShipment: null,
    filterData: null,
    allshimpentsLenght:null,
    viewShipmentId:null
}

const allShipmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_WALLET_BALANCE":
            return {
                ...state,
                currentBalance: action.payload
            }
        case "GET_ALL_SHIPMENTS": 
            return {
                ...state,
                allshimpents: action.payload
            }
        case "GET_ALL_SHIPMENTS_LENGHT": 
            return {
                ...state,
                allshimpentsLenght: action.payload
            }
        case "POST_VIEW_SHIPMENTS":
            return {
                ...state,
                viewShipment: action.payload,
                viewShipmentId: action.payload?.shipment?.id
            }
        case "GET_FILTER_DATA":
            return {
                ...state,
                filterData: action.payload
            }
        case "GET_FILTER_SHIPMENTS":
            return {
                ...state,
                allshimpents: action.payload
            }
        case "GET_ALL_CARRIERS":
            return {
                ...state,
                allCarriers: action?.payload?.carriers
            }
        default:
            return state
    }
}

export default allShipmentsReducer;
