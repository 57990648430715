const initialState = {
    documentLink: null,
    palletLable:null
}

const viewDocumentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DOCUMENT_LINK":
            return {
                ...state,
                documentLink: action.payload
            }
        case "GET_PALLET_LABLE":
            return {
                ...state,
                palletLable: action.payload
            }
        default:
            return state
    }
}

export default viewDocumentReducer;
