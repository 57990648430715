import React from 'react';
import PropTypes from 'prop-types';

export default function LTLQuotestIcon({ fill, sx }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill} style={sx}>
    <g id="vuesax_bulk_convertshape" data-name="vuesax/bulk/convertshape" transform="translate(-556 -380)">
      <path id="Vector" d="M9,3.15v3.7C9,9.1,8.1,10,5.85,10H3.15C.9,10,0,9.1,0,6.85V3.15C0,.9.9,0,3.15,0h2.7C8.1,0,9,.9,9,3.15Z" transform="translate(569 382)" opacity="0.4"/>
      <path id="Vector-2" data-name="Vector" d="M9,3.15v3.7C9,9.1,8.1,10,5.85,10H3.15C.9,10,0,9.1,0,6.85V3.15C0,.9.9,0,3.15,0h2.7C8.1,0,9,.9,9,3.15Z" transform="translate(558 392)" opacity="0.4"/>
      <path id="Vector-3" data-name="Vector" d="M.746,7.98A.752.752,0,0,1,.1,7.6a.764.764,0,0,1,.01-.76l.97-1.62a.751.751,0,1,1,1.29.77l-.18.3A5.728,5.728,0,0,0,6.476.75a.745.745,0,1,1,1.49.01A7.23,7.23,0,0,1,.746,7.98Z" transform="translate(570.034 394.02)"/>
      <path id="Vector-4" data-name="Vector" d="M.75,7.97A.749.749,0,0,1,0,7.22,7.23,7.23,0,0,1,7.22,0a.769.769,0,0,1,.66.38.764.764,0,0,1-.01.76L6.9,2.75a.751.751,0,1,1-1.29-.77l.18-.3A5.74,5.74,0,0,0,1.5,7.22.749.749,0,0,1,.75,7.97Z" transform="translate(558 382)"/>
      <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(556 380)" fill="none" opacity="0"/>
    </g>
  </svg>
  
  
  );
}

LTLQuotestIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

LTLQuotestIcon.defaultProps = {
  fill: '#4d4663',
  sx: {},
};
