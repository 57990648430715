import {
    api,
    apiEndPoints,
} from '../../api';
export const POST_SHIPMENT_DATA = `POST_SHIPMENT_DATA`;

const posViewShipments = (payload) => {
  return {
    type: POST_SHIPMENT_DATA,
    payload: payload.data,
  };
};

export const postViewShipmentsData = (id) => async (dispatch) => {
    try {
        const response = await api.post(
            apiEndPoints.postViewShipmentsDetails(),id
        );
        dispatch(posViewShipments(response))

    } catch (error) {
        const { response: { data = {} } = {} } = error;
        return data;
    }
}