import {
    api,
    apiEndPoints,
} from '../../api';

export const POST_ENTER_SHIPMENT = `POST_ENTER_SHIPMENT`;
export const ERROR_ENTER_SHIPMENT = `ERROR_ENTER_SHIPMENT`;

const postEnterShipment = (payload) => {
    return{
        type: POST_ENTER_SHIPMENT,
        payload: payload,
    }
};
const errorEnterShipment = (payload) => {
    return{
        type: ERROR_ENTER_SHIPMENT,
        payload: payload,
    }
};

export const postEnterShipmentDetails = (data) => async (dispatch) => {
    try {
        const res = await api.get(
            apiEndPoints.postShipmentDetails(data.shipment_id)
        );
        dispatch(postEnterShipment(res.data?.shipment))
        if(res.response.status === 401){
            dispatch(errorEnterShipment(res.response.data.message))
        }
    } catch (error) {
        const { response: { data = {} } = {} } = error;
        return data;
    }
}