const initialState = {
    getCarriersData: null,
    postRateOfCarrier: null,
    postTransistData: null,
    acceptQuoteData: null,
    quoteInsurance: null,
    postRateOffailCarrier:null
}

const GuestQuoteReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_QUOTE_DETAILS":
            return {
                ...state,
                getCarriersData: action.payload,
                postRateOffailCarrier: null,
                postRateOfCarrier:null
            }
        case "POST_RATE_OF_CARRIER":
            return {
                ...state,
                postRateOfCarrier:state.postRateOfCarrier==null?[action.payload]:[...state.postRateOfCarrier,action.payload]
            }
        case "POST_RATE_OF_FAIL_CARRIER":
            return {
                ...state,
                postRateOffailCarrier:state.postRateOffailCarrier===null?[action.payload]:[...state.postRateOffailCarrier,action.payload]
            }
        case "POST_TRANSIST_DATA":
            return {
                ...state,
                postTransistData: action.payload
            }
        case "POST_QUOTE_ACCEPT":
            return {
                ...state,
                acceptQuoteData: action.payload
            }
        case "POST_QUOTE_INSURANCE":
            return {
                ...state,
                quoteInsurance: action.payload
            }
        default:
            return state
    }
}

export default GuestQuoteReducer;