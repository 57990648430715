import React from "react";
import {
    Dashboard
} from '@mui/icons-material'
import ShipmentsIcon from '../../../assets/icons/Shipments';
import LTLQuotesIcon from '../../../assets/icons/LTLQuotes';
import LTLDefault from '../../../assets/icons/LTLDefault';
import SFLocationIcon from "../../../assets/icons/SFLocation";
import SettingIcon  from "../../../assets/icons/Setting"
import SupportIcon from '@mui/icons-material/Support';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import SettingsIcon from '@mui/icons-material/Settings';

const Routes = [
    {
        name: 'Shipments',
        icon: <ShipmentsIcon />,
        layout: '/',
        display: true
    },
    {
        name: 'LTL Quotes',
        icon: <LTLDefault />,
        layout: '/shipment-detail',
        display: true
    },
    {
        name: 'LTL Quotes',
        icon: <LTLQuotesIcon />,
        layout: '/ltl-quotes',
        display: false
    },
    {
        name: 'LTL Quotes',
        icon: <LTLQuotesIcon />,
        layout: '/quote-accept',
        display: false
    },
    {
        name: 'LTL Quotes',
        icon: <LTLQuotesIcon />,
        layout: '/shipment-address',
        display: false
    },
    {
        name: 'Settings',
        icon: <SettingIcon />,
        // layout: '',
        display: true
    },
    {
        name: 'General',
        icon: <SettingsIcon />,
        layout: '/setting/general-settings',
        display: false
    },
    {
        name: 'Payment Types',
        icon: <Dashboard />,
        layout: '/setting/payment-type',
        display: false
    },
    {
        name: 'Address Book',
        icon: <SFLocationIcon />,
        layout: '/address-book',
        display: false
    },
    {
        name: 'Preset Commodity',
        icon: <LTLQuotesIcon />,
        layout: '/preset-commodities',
        display: false
    },
    {
        name: 'Support',
        icon: <SupportIcon />,
        layout: '/support',
        display: true
    },
    {
        name: 'FAQ',
        icon: <LiveHelpIcon />,
        layout: '/faq-page',
        display: true
    },

]

export default Routes
