export default {
  // getLocationAndPickupApi: (id) => `https://gli.logisticallytms.com/api/v3/enums?api_key=${id}`,
  getCookieToken: () => `customer/getCookie`,
  getAddressesApi: () => `address/allAdresses`,
  getUserToken: (id) => `users/token/get?token=${id}`,
  getWalletBalance: () => `customer/current-balance`,
  getShipmentDatas: () => `shipments`,
  getWalletHistory: () => `customer/my-wallet`,
  getPaymentType: () => `payment`,
  getProfileDetail: () => `users`,
  getUpdateProfileDetail: () => `users/profile`,
  deleteAddressLocation: (id) => `address/${id}`,
  getSearchAddress: (key) => `address/search/${key}`,
  deleteCard: (id) => `payment/removePaymentSource/${id}`,
  setDefaultCard: () => `payment/setAsDefault`,
  getGeneralDataApi: () => `users/general/setting?=`,
  getPickupService: () => `customer/getPickupServicesData`,
  setUpdateGeneralData: () => `users/general/setting/store`,
  getDeliveryService: () => `customer/getDeliveryServicesData`,
  getHandlingService: () => `customer/getHandlingTypeData`,
  postPaymentsType: () => `payment/saveCard`,
  postAddAddress: () => `address`,
  getAddressTypes: () => `address/get/location`,
  putUpdateAddress: (id) => `address/${id}/update`,
  postEditPaymentsType: () => `payment/updateCard`,
  postwalletRecharge: () => `customer/recharge-wallet`,
  getQuoteDetails: () => `customer/getQouteRates/infoPage`,
  postSaveInfo: () => `customer/saveShipment/infoPage`,
  postRateCarrier: () => `customer/getParticularQouteRates/infoPage`,
  postGetCarrier: () => `customer/getTransitDetails/infoPage`,
  postQuoteAccept: () => `customer/acceptQoute`,
  postShipmentDetails: (id) => `customer/getShipmentDetails/${id}`,
  postCreateShipment: () => `customer/shipment`,
  postInformationApi: () => `customer/shipment/information`,
  postCargoInsurence: () => `cargo/quote`,
  postCargoInsurenceData: () => `customer/shipment/cargoInsurance`,
  postCargoInsurenceCertificate: () => `cargo/certificate`,
  postViewShipmentsDetails: () => `customer/shipment/shipments`,
  postPublicTrackingLink: () => `customer/shipment/shipments/tracking`,
  postPublicTrackingdData: () => `customer/shipment/tracking`,
  postTrackNow: () => `customer/shipment/trackNow`,
  postFilterData: () => `customer/shipment/filterData`,
  postDeleteShpment: () => `customer/shipment/requestCancelShipment`,
  getDocumentBol: (id) => `customer/shipment/${id}/docs`,
  getFilteredData: (time, status, date, carrier) =>
    `shipments/filter?${time ? "filter_time=" + time + "&" : ""}${
      status ? "shipment_status=" + status + "&" : ""
    }${date ? date + "&" : ""}${carrier ? carrier + "&" : ""}`,
  postRefundShipment: () => `customer/shipment/refundShipmentRequest`,
  postRefundCencellation: () => `customer/shipment/cancelRefundRequest`,
  getGuestDeliveryService: () => `guest/getDeliveryServicesData`,
  getGuestRemainingAttempts: () => `guest/remainingRequests`,
  getGuestPickupservice: () => `guest/getPickupServicesData`,
  getGuestHandlingTypes: () => `guest/getHandlingTypeData`,
  getGuestSaveInfo: () => `guest/guestSaveInfo`,
  getGuestCarrierInfo: () => `guest/getQouteRates/infoPage`,
  getGuestParticularCarrierInfo: () =>
    `guest/getGuestParticularCarrierRates/infoPage`,
  getGeneralUserData: () => `users/general/setting/userfetch`,
  setUserTypeGeneral: () => `users/general/setting/addFreightClass`,
  getUserTypeGeneral: () => `customer/userType`,
  getUserFreightClass: () => `users/general/setting/freightClassCheck`,
  getPalletLable: () =>
    `${process.env.REACT_APP_API_BASE_URL}/customer/shipment/downloadPalletLabel`,
  downloadBOL: (id) =>
    `${process.env.REACT_APP_API_BASE_URL}/customer/shipment/${id}/downloadBOL`,
  getCargoInsurenceData: (id) =>
    `${process.env.REACT_APP_API_BASE_URL}/cargo/certificate/${id}`,
  getCityZipAddress: () => `address/getAddressByCityOrZipcode`,
  getQouteData: (id) => `customer/editQouteDetails/infoPage/${id}`,
  postTrackingEmail: () => `customer/shipment/saveTrackingEmails`,
  getFastTrackingData: () => `/customer/shipment/publicTrackingLink`,
  getAllPresetCommodities: () => `/presets`,
  postPresetCommodities: () => `/presets/store`,
  updatePresetCommodities: (id) => `/presets/update/${id}`,
  getViewPreset: (id) => `/presets/${id}`,
  getCheckoutPaymentData: () => `/customer/currentUser/checkoutDetails`,
  getCarrierRateFailAll: () =>
    `/customer/shipment/sendMailForFailedRatesToAdmin`,
  postSendFileClaim: () => `users/sendFileAClaim`,
  getAllCarriers: () => `guest/getAllCarriers`,
  checkIfUsersLeavePageBetween: () =>
    `/customer/shipment/check_if_user_leaves_page_in_between`,
  getCustomDimensions: () => "/customer/custom-dimension-setting",
  triggerMailForTradeShow: () =>
    "/customer/shipment/sendMailIfTradeShowSelected",
  triggerMailForLinearFeet: () => "/customer/shipment/sendmailforratepageissue",
};
