import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Verified } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import { Steps } from "./constant/index";
import QuoteAcceptedHooks from "./QuoteAcceptedHooks";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CustomButton from "../../components/Button";
import "./index.scss";
import { getCarrierLogo } from "../../utils/utils";
import { snakeToCamel } from "../../utils/helper";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import CircularLoader from "../../components/Loader";

function QuoteAccepted(props) {
  const {
    activeStep,
    AcceptQuoteData,
    handleNext,
    newQuote,
    shipmentDetail,
    loading,
    message,
    density,
    mobileMatch,
  } = QuoteAcceptedHooks();

  const renderCarrierName = (name) => {
    if (name === "TForce Freight 2.0") {
      return "TForce";
    }
    if (name === "Averitt Express (Dynamic Pricing)") {
      return "Averitt Express";
    }
    return name;
  };

  return (
    <div style={{ backgroundColor: "#fff", borderRadius: "18px" }}>
      {loading ? (
        <CircularLoader />
      ) : props?.ViewQuote?.AcceptQuoteData || AcceptQuoteData ? (
        <Box
          sx={{
            marginLeft: "30px",
            marginTop: { xs: "20px", md: "20px", lg: "20px" },
          }}
        >
          <Grid
            container
            spacing={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{ flexGrow: 1 }}
              style={
                props?.AddressShipment && mobileMatch
                  ? { padding: "0px" }
                  : { padding: "22px 22px 30px" }
              }
            >
              <Grid
                spacing={2}
                sx={{ width: "100%" }}
                style={{ backgroundColor: "#FBFBFB", borderRadius: "8px" }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{ width: "100%" }}
                  style={{ margin: "10px", padding: "5px 0 15px" }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{ gap: 10, display: "flex", color: "#00C18C" }}
                  >
                    <Verified />
                    <Typography style={{ fontWeight: "600" }}>
                      {AcceptQuoteData?.message}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  sx={{ width: "100%" }}
                  style={{
                    margin: "10px",
                    padding: "5px 0 15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "26px",
                    }}
                    md={4}
                    xs={11}
                    sm={5}
                    lg={3.5}
                    xl={1.7}
                  >
                    <Grid
                      item
                      xs={2}
                      sm={1.5}
                      md={2}
                      lg={3}
                      style={{ margin: "3px -24px 0px 0px" }}
                    >
                      <img
                        src={getCarrierLogo(
                          AcceptQuoteData?.freight_carrier_id
                        )}
                        width={40}
                        alt={AcceptQuoteData?.freight_carrier_id}
                        height={40}
                        style={{ border: "1px solid", borderRadius: "50%" }}
                      />
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} lg={9}>
                      <Typography style={{ fontWeight: "600" }}>
                        {renderCarrierName(AcceptQuoteData.carrier_name)}
                      </Typography>
                      <Typography className="accordion-label">
                        Order# : {AcceptQuoteData?.shipment_order_number}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-around",
                      marginBottom: "26px",
                    }}
                    md={6}
                    xs={12}
                    sm={6}
                    lg={6}
                    xl={4}
                  >
                    {AcceptQuoteData?.insurance_cost && (
                      <Grid item xs={3}>
                        <Typography
                          className="accordion-label"
                          style={{ fontSize: "0.9rem" }}
                        >
                          Insurance Cost
                        </Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          $ {AcceptQuoteData?.insurance_cost.toFixed(2)}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <Typography
                        className="accordion-label"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Special Services
                      </Typography>
                      <Typography style={{ fontWeight: "600" }}>
                        $ {AcceptQuoteData?.accessorial_cost?.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        className="accordion-label"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Fuel
                      </Typography>
                      <Typography style={{ fontWeight: "600" }}>
                        $ {AcceptQuoteData?.fuel_surcharge?.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        className="accordion-label"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Total Cost
                      </Typography>
                      {AcceptQuoteData?.upgrades_and_garantees?.upgrade_cost ? (
                        <Typography style={{ fontWeight: "600" }}>
                          ${" "}
                          {AcceptQuoteData?.upgrades_and_garantees?.upgrade_cost?.toFixed(
                            2
                          )}
                        </Typography>
                      ) : (
                        <Typography style={{ fontWeight: "600" }}>
                          $ {AcceptQuoteData?.total_cost?.toFixed(2)}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />

                <Grid
                  container
                  spacing={2}
                  sx={{ width: "100%" }}
                  className="quote-step"
                  style={{ margin: "10px", padding: "5px 0 15px" }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography style={{ fontWeight: "600" }}>
                      {AcceptQuoteData?.miles} <span>Miles, </span>
                      {AcceptQuoteData?.transit_days} <span>Days Transit</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={11.8}>
                    {mobileMatch ? (
                      <Timeline
                        style={{ padding: "20px 18px" }}
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                          },
                        }}
                      >
                        <TimelineItem>
                          <TimelineSeparator className="Line">
                            <TimelineDot
                              className="activeDot"
                              color="warning"
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography>
                              {AcceptQuoteData?.origin?.address1
                                ? `${AcceptQuoteData?.origin?.address1}, `
                                : ""}
                              {AcceptQuoteData?.origin?.city
                                ? `${AcceptQuoteData?.origin?.city}, `
                                : ""}
                              {AcceptQuoteData?.origin?.state
                                ? `${AcceptQuoteData?.origin?.state} `
                                : ""}
                              {AcceptQuoteData?.origin?.zipcode
                                ? `${AcceptQuoteData?.origin?.zipcode},`
                                : ""}{" "}
                              US
                            </Typography>
                            <Typography
                              className="accordion-label"
                              style={{ color: "#181C2F", fontWeight: "500" }}
                            >
                              {AcceptQuoteData?.shipdate?.slice(5, 10) +
                                "-" +
                                AcceptQuoteData?.shipdate?.slice(0, 4)}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator className="Line">
                            <TimelineDot color="warning" />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography>
                              {AcceptQuoteData?.destination?.address1
                                ? AcceptQuoteData?.destination?.address1 + ", "
                                : ""}
                              {AcceptQuoteData?.destination?.city
                                ? AcceptQuoteData?.destination?.city + ", "
                                : ""}
                              {AcceptQuoteData?.destination?.state
                                ? AcceptQuoteData?.destination?.state + " "
                                : ""}
                              {AcceptQuoteData?.destination?.zipcode
                                ? AcceptQuoteData?.destination?.zipcode + ","
                                : ""}{" "}
                              US
                            </Typography>
                            <Typography
                              className="accordion-label"
                              style={{ color: "#181C2F", fontWeight: "500" }}
                            >
                              {AcceptQuoteData?.shipdate?.slice(5, 10) +
                                "-" +
                                AcceptQuoteData?.shipdate?.slice(0, 4)}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    ) : (
                      ""
                    )}
                    {!mobileMatch ? (
                      <Stepper activeStep={activeStep}>
                        {Steps.map((label, index) => {
                          return (
                            <Step key={index} onClick={() => handleNext(index)}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                    ) : (
                      ""
                    )}
                    {!mobileMatch ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          padding: "12px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid
                          style={{ width: "50%" }}
                          item
                          xs={12}
                          md={5}
                          lg={4}
                          sm={6}
                        >
                          <Typography style={{ fontWeight: "600" }}>
                            {AcceptQuoteData?.origin?.address1
                              ? AcceptQuoteData?.origin?.address1 + ", "
                              : ""}
                            {AcceptQuoteData?.origin?.city
                              ? AcceptQuoteData?.origin?.city + ", "
                              : ""}
                            {AcceptQuoteData?.origin?.state
                              ? AcceptQuoteData?.origin?.state + " "
                              : ""}
                            {AcceptQuoteData?.origin?.zipcode
                              ? AcceptQuoteData?.origin?.zipcode + ","
                              : ""}{" "}
                            US
                          </Typography>
                          <Typography className="accordion-label">
                            {AcceptQuoteData?.shipdate?.slice(5, 10) +
                              "-" +
                              AcceptQuoteData?.shipdate?.slice(0, 4)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={5}
                          lg={4}
                          style={{ textAlign: "right", width: "50%" }}
                        >
                          <Typography style={{ fontWeight: "600" }}>
                            {AcceptQuoteData?.destination?.address1
                              ? AcceptQuoteData?.destination?.address1 + ", "
                              : ""}
                            {AcceptQuoteData?.destination?.city
                              ? AcceptQuoteData?.destination?.city + ", "
                              : ""}
                            {AcceptQuoteData?.destination?.state
                              ? AcceptQuoteData?.destination?.state + " "
                              : ""}
                            {AcceptQuoteData?.destination?.zipcode
                              ? AcceptQuoteData?.destination?.zipcode + ","
                              : ""}{" "}
                            US
                          </Typography>
                          <Typography className="accordion-label">
                            {AcceptQuoteData?.delivery_date?.slice(5, 10) +
                              "-" +
                              AcceptQuoteData?.delivery_date?.slice(0, 4)}
                          </Typography>
                        </Grid>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  sx={{ width: "100%" }}
                  style={{ margin: "10px", padding: "5px 0 15px" }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{ padding: "16px 35px 0px 12px" }}
                  >
                    <Typography className="accordion-label">
                      Accessorials
                    </Typography>
                    {AcceptQuoteData?.upgrades?.map((item, index) => (
                      <Typography
                        style={{ fontWeight: "600", display: "inline-block" }}
                      >
                        {item?.accessorial_type
                          ? snakeToCamel(item?.accessorial_type)
                          : " "}
                        {index < AcceptQuoteData?.upgrades?.length - 1
                          ? `,\u00A0`
                          : " "}
                      </Typography>
                    ))}
                    {AcceptQuoteData?.upgrades_and_garantees?.length > 0 &&
                      AcceptQuoteData?.upgrades_and_garantees[0]
                        ?.upgrade_cost && (
                        <div>
                          <Typography className="accordion-label">
                            Upgrades and Guarantee
                          </Typography>
                          {AcceptQuoteData?.upgrades_and_garantees[0]
                            ?.upgrade_cost && (
                            <Typography
                              style={{
                                fontWeight: "600",
                                display: "inline-block",
                              }}
                            >
                              {
                                AcceptQuoteData?.upgrades_and_garantees[0]
                                  ?.formatted_description
                              }
                            </Typography>
                          )}
                        </div>
                      )}
                  </Grid>

                  <Grid item xs={12} lg={11.9} style={{ paddingLeft: "0" }}>
                    {AcceptQuoteData?.handlingUnits &&
                      AcceptQuoteData?.handlingUnits.map((item, index) => (
                        <Card className="quote-accepted-box">
                          <CardContent>
                            <Grid
                              style={{
                                display: "flex",
                                marginBottom: "15px",
                                flexWrap: "wrap",
                              }}
                            >
                              <Grid item xs={12} md={6} lg={2} xl={3}>
                                <Typography style={{ fontWeight: "600" }}>
                                  {"Handling Type"} - {item?.handling_type}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6} lg={3} xl={3}>
                                <Typography style={{ fontWeight: "600" }}>
                                  {"Commodity"} -{" "}
                                  {item?.commodities.map(
                                    (u) => u?.nmfc_description
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={6} sm={4} md={3} lg={1}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Length (In)
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.length}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Width (In)
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.width}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Height (In)
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.height}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Handling Units
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.num_skids}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1.6}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Total Weight (Lbs)
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.commodities.map((u) => u?.weight)}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Total Pieces
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.commodities.map(
                                    (u) => u?.number_of_units
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Density
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {density(
                                    item?.commodities.map((u) => u?.weight),
                                    item?.height,
                                    item?.width,
                                    item?.length,
                                    item?.num_skids
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Freight Class
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.commodities.map(
                                    (u) => u?.freight_class
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1.3}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Cust Order/PO#
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.commodities[0]?.po_number}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3} lg={1.2}>
                                <Typography className="accordion-label accordion-label-small stats-accordian-label">
                                  Shipper info
                                </Typography>
                                <Typography style={{ fontWeight: "600" }}>
                                  {item?.commodities.map(
                                    (u) => u?.shipper_number
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      ))}
                  </Grid>
                </Grid>
                {props?.AddressShipment ? (
                  ""
                ) : (
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "100%" }}
                    style={{ margin: "10px", padding: "5px 6px 15px" }}
                    className="quote-accepted-btn"
                  >
                    <Grid item xs={5} sm={3} md={1.7} xl={1.1}>
                      <CustomButton
                        type={"add"}
                        label={"New Quote"}
                        variant={"outlined"}
                        buttonOnClick={newQuote}
                        LineHeight={"1.1"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={4}
                      md={2.8}
                      xl={1.9}
                      className="quote-accepted-btn-detail"
                    >
                      <CustomButton
                        type={"add"}
                        label={"Enter Shipment Detail"}
                        buttonOnClick={shipmentDetail}
                        LineHeight={"1.1"}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Box>
      ) : (
        <div style={{ background: "#ff2323", width: "100%" }}>
          <Typography style={{ fontWeight: "600", textAlign: "center" }}>
            {message}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default QuoteAccepted;
