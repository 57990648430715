import React, {useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router';
import ReactGA4 from 'react-ga4';
const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA4.set({ page: location.pathname });
        ReactGA4.send({ hitType: "pageview", page: location.pathname  });
    }, [location]);
    return <></>;
};

export default RouteChangeTracker;