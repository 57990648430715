const initialState = {
    ShipmentsDetails: null,
}

const VieShipmentsDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "POST_SHIPMENT_DATA":
            return {
                ...state,
                ShipmentsDetails: action.payload
            }
        default:
            return state
    }
}

export default VieShipmentsDataReducer;
