import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';

export default function CircularLoader() {

    return (
        <Box
        sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <CircularProgress color="warning"/>
    </Box>
    );
}