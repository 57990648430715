const initialState = {
    locationPickup: null,
    checkoutDetails: null
}

const shipmentAddressReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_LOCATION_AND_PICKUP":
            return {
                ...state,
                locationPickup: action.payload
            }
        case "GET_CHECKOUT_DETAILS":
            return {
                ...state,
                checkoutDetails: action.payload
            }
        default:
            return state
    }
}

export default shipmentAddressReducer;
