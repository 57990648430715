const initialState = {
    PaymentTypes: null
}

const PaymentTypeSReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PAYMENT_TYPES":
            return {
                ...state,
                PaymentTypes: action.payload
            }
        default:
            return state
    }
}

export default PaymentTypeSReducer;
