import React from 'react';
import PropTypes from 'prop-types';

export default function ProfileIcon({ fill, sx }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43.182" height="43.182" viewBox="0 0 43.182 43.182" fill={fill} style={sx}>
      <g id="vuesax_bulk_profile-circle" data-name="vuesax/bulk/profile-circle" transform="translate(-492 -252)">
        <g id="profile-circle" transform="translate(492 252)">
          <path id="Vector" d="M35.985,17.992A17.992,17.992,0,1,1,17.992,0,17.992,17.992,0,0,1,35.985,17.992Z" transform="translate(3.598 3.616)" fill="#f85f00" opacity="0.4" />
          <path id="Vector-2" data-name="Vector" d="M6.747,0a6.738,6.738,0,0,0-.09,13.476h.324A6.74,6.74,0,0,0,6.747,0Z" transform="translate(14.844 12.487)" fill="#f85f00" />
          <path id="Vector-3" data-name="Vector" d="M24.4,6.738a17.948,17.948,0,0,1-12.2,4.768A17.948,17.948,0,0,1,0,6.738,7.691,7.691,0,0,1,3.311,2.456c4.912-3.275,12.9-3.275,17.776,0A7.613,7.613,0,0,1,24.4,6.738Z" transform="translate(9.392 28.095)" fill="#f85f00" />
          <path id="Vector-4" data-name="Vector" d="M0,0H43.182V43.182H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
}

ProfileIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

ProfileIcon.defaultProps = {
  fill: '#4d4663',
  sx: {},
};
