import { toast } from "react-toastify";
import { api, apiEndPoints } from "../../api";
export const GET_ALL_PRESET_COMMODITIES = "GET_ALL_PRESET_COMMODITIES";
export const GET_VIEW_PRESET_COMMODITIES = "GET_VIEW_PRESET_COMMODITIES";

const getPresetCommodities = (payload) => {
  return {
    type: GET_ALL_PRESET_COMMODITIES,
    payload: {payload : payload?.data?.data, countOfData: payload?.data?.allRecordsCount}
  };
};
const getViewPresetCommodities = (payload) => {
  return {
    type: GET_VIEW_PRESET_COMMODITIES,
    payload: payload?.data,
  };
};
export const getAllPresetCommoditiesData = (data) => async (dispatch) => {
  try {
    const response = await api.post(
      apiEndPoints.getAllPresetCommodities(),
      data
    );
    dispatch(getPresetCommodities(response));
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};
export const getViewPreset = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getViewPreset(id));
    dispatch(getViewPresetCommodities(response.data));
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};
export const postPresetData = (data, navigate,setNameError) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.postPresetCommodities(), data);
    if (response.status) {
      navigate(`/preset-commodities`);
      toast.success("Preset commodity saved successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1200,
      });
    } else {
      const errorMessage = response?.response?.data?.message?.toLowerCase();
      toast.error(errorMessage?errorMessage[0]?.toUpperCase()+errorMessage?.slice(1):"Preset commodity saved unsuccessfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      errorMessage&&setNameError(true);
      window.scrollTo(0, 0);
    }
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};
export const updatePresetData = (data, navigate,id,presetPage,setNameError) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.updatePresetCommodities(id), data);
    if (response.status) {
      if(!presetPage){navigate(`/preset-commodities`)}
      toast.success("Preset commodity updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1200,
      });
    } else {
      const errorMessage = response?.response?.data?.message?.toLowerCase();
      toast.error(errorMessage?errorMessage[0]?.toUpperCase()+errorMessage?.slice(1):"Preset commodity updated unsuccessfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      errorMessage&&setNameError(true);
      window.scrollTo(0, 0);
    }
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};

export const deletePresetData = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.getViewPreset(id));
    if (response.status) {
      toast.success("Preset commodity deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1200,
      });
    } else {
      toast.error("Preset commodity deleted unsuccessfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1200,
      });
    }
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};