import { combineReducers } from 'redux';
import actionLoaderReducer from './api-action-loading/reducer';
import shipmentReducer from "../pages/ShipmentDetails/reducer";
import addressBookReducer from '../pages/AddressBook/reducer';
import shipmentAddressReducer from "../pages/ShipmentAddress/reducer";
import allShipmentsReducer from '../pages/Shipments/reducer';
import walletHistoryReducer from '../pages/MyWallet/reducer';
import PaymentTypeSReducer from "../pages/PaymentTypesCard/reducer";
import myProfileReducer from '../pages/Profile/reducer';
import generalDetailReducer from '../pages/GeneralSettings/reducer';
import quoteReducer from '../pages/Quote/reducer';
import AcceptQuoteReducer from '../pages/QuoteAccepted/reducer';
import addAddressReducer from '../pages/Addaddress/reducer';
import CargoShipmentReducer from '../pages/ViewShipmentCargo/reducer';
import VieShipmentsDataReducer from '../pages/View-Shipments/reducer';
import ShipmentTrackingReducer from '../pages/ViewShipmentsTracking/reducer';
import viewDocumentReducer from '../pages/ViewShipmentsDocument/reducer';
import viewShipmentReducer from '../pages/ViewShipment/reducer';
import quickrateReducer from '../pages/QuickRateQuote/reducer';
import GuestQuoteReducer from '../pages/GuestQuotes/reducer';
import sideBarReducer from '../components/sidebar/reducer';
import publicTrackingReducer from '../pages/FastOrderTracking/reducer';
import presetCommoditiesReducer from '../pages/PresetCommodities/reducer';

const createReducer = () => {
    const rootReducer = combineReducers({
        actionLoaderReducer: actionLoaderReducer,
        shipmentReducer: shipmentReducer,
        addressBookReducer: addressBookReducer,
        shipmentAddressReducer: shipmentAddressReducer,
        allShipmentsReducer: allShipmentsReducer,
        walletHistoryReducer: walletHistoryReducer,
        PaymentTypeSReducer: PaymentTypeSReducer,
        myProfileReducer: myProfileReducer,
        generalDetailReducer: generalDetailReducer,
        quoteReducer: quoteReducer,
        AcceptQuoteReducer: AcceptQuoteReducer,
        addAddressReducer: addAddressReducer,
        CargoShipmentReducer: CargoShipmentReducer,
        VieShipmentsDataReducer: VieShipmentsDataReducer,
        ShipmentTrackingReducer: ShipmentTrackingReducer,
        viewDocumentReducer: viewDocumentReducer,
        viewShipmentReducer: viewShipmentReducer,
        GuestQuoteReducer: GuestQuoteReducer,
        publicTrackingReducer: publicTrackingReducer,
        quickrateReducer: quickrateReducer,
        sideBarReducer:sideBarReducer,
        presetCommoditiesReducer:presetCommoditiesReducer,
    })
    return rootReducer;
};

export default createReducer;

