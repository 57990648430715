import * as React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@mui/material";
import "./radioButton.scss";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


export default function RadioButton(props) {
  const { item,  setState, state, xs, md, xl, lg, index, handleTypes, upgradesServices } = props;
  const handleChange = (event) => {
    if(handleTypes){
      const aa = [...handleTypes];
      aa.forEach((item, i) =>
      i == index ? (item.handling_type = event.target.value) : item
      );
      setState(aa);
    }else{
      const aa = [...upgradesServices];
      aa.forEach((i, j) =>
      j == index ? (i.SelectedServiceData?.id==event.target.value?(i.SelectedServiceData = {}):(i.SelectedServiceData = {Service:item[event.target.value].service_type,date:item[event.target.value].delivery_date,cost:item[event.target.value].total_cost,id:event.target.value,hash:item[event.target.value].hash,upgrade_cost:item[event.target.value].upgrade_cost,formatted_description:item[event.target.value].formatted_description})) : i
      );
      setState(aa);
    }
  };

  const handleClick = (event) => {
    const aa = [...upgradesServices];
      aa.forEach((i, j) =>
      j == index ? (i.SelectedServiceData?.id==event.target.value?(i.SelectedServiceData = {}):(i.SelectedServiceData = {Service:item[event.target.value].service_type,date:item[event.target.value].delivery_date,cost:item[event.target.value].total_cost,id:event.target.value,hash:item[event.target.value].hash,upgrade_cost:item[event.target.value].upgrade_cost,formatted_description:item[event.target.value].formatted_description})) : i
      );
      setState(aa);
  }

  return (
    <div>
      {state?.handling_type ? (
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            className="radioButton"
            value={state?.handling_type}
            onChange={handleChange}
            
            style={{ marginLeft: "10px", padding: "10px" }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                className="radio-box"
                style={{ marginLeft: "-2px" }}
              >
                {item?.map((v) => (
                  <>
                    <Grid className="radio" key={v.id} item xs={xs} md={md || xs} lg={lg} xl={xl || lg}>
                      <FormControlLabel
                        value={v.val}
                        control={<Radio color="warning"/>}
                        label={
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={v?.img ? v?.img : ""}
                              alt={v?.desc}
                              width={"35px"}
                            />
                            {v?.desc}
                          </div>
                        }
                        key={v.val}
                      />
                      <Tooltip title={v?.tip ? v?.tip : ""}>
                        <InfoOutlinedIcon style={{ color: "#626262", marginRight: "10px" }} />
                      </Tooltip>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
          </RadioGroup>
        </FormControl>
      ) : (
        <FormControl style={{width:'100%'}}>
          <RadioGroup defaultValue={state} value={upgradesServices[index].SelectedServiceData?.id}>
            {item?.map((v, i) => (
              <div onClick={handleClick} className="RadioServices">
                <FormControlLabel
                  style={{width:'100%'}}
                  value={i}
                  control={<Radio/>}
                  label={
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: 'space-between'
                      }}
                    ><div className="buttons">
                      <span>{v.service_type}</span>
                      <span>{v.delivery_date?.slice(5,10)+"-" +v.delivery_date?.slice(0,4)+' '+v?.delivery_time?v?.delivery_time:"Not Available"}</span>
                    </div>
                    <div >
                      <span className="cost" style={{fontweight:"900px"}}>${v?.total_cost?.toFixed(2)}</span>
                      </div>
                    </div>
                  }
                  key={v.i}
                />
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </div>
  );
}
