import * as React from 'react';
import Button from "@mui/material/Button";
import './Button.scss'

export default function CustomButton(props) {
    const { type, label, buttonOnClick, icon, variant, width, Padding, LineHeight, FontSize, buttonType, disable,link, color , margin} = props

    const addButton = () => {
        return(
            <Button disabled={disable} className={variant === 'outlined' ? 'outlinedAddButton' : 'addButton'} variant='' startIcon={icon} onClick={buttonOnClick} style={{width: width,fontSize:FontSize , padding: Padding, lineHeight:LineHeight,margin:margin} } type={buttonType} href={link}>
                {label}
            </Button>
        )
    }

    const transitButton = () => {
        return(
            <Button variant="outlined" className={'transitButton'} color={color} startIcon={icon} onClick={()=>buttonOnClick()} style={{width: width, padding: Padding, lineHeight:LineHeight,fontSize:FontSize,borderRadius:'8px'}} type={buttonType}>{label}</Button>
        )
    }

    const acceptButton = () => {
        return(
            <Button variant="contained" color="success" className={'acceptButton'} startIcon={icon} onClick={buttonOnClick} style={{width: width, padding: Padding, lineHeight:LineHeight,fontSize:FontSize,borderRadius:'8px'}} type={buttonType}>{label}</Button>
        )
    }
    
    const outlinedCancelledButton = () => {
        return(
            <Button variant="contained" color="error" className={'outlinedCancelledButton'} startIcon={icon} onClick={buttonOnClick} style={{width: width, padding: Padding, lineHeight:LineHeight,fontSize:FontSize,borderRadius:'8px'}} type={buttonType}>{label}</Button>
        )
    }

    const outlinedAcceptButton = () => {
        return(
            <Button variant="contained" color="success" className={'outLinedAcceptButton'} startIcon={icon} onClick={buttonOnClick} style={{width: width, padding: Padding,lineHeight:LineHeight,fontSize:FontSize}} type={buttonType}>{label}</Button>
        )
    }
    const shipmentTrackingStatus = () => { 
        return(
            <Button variant="contained" color="primary" className={'shipmentTrackingStatus'} startIcon={icon} onClick={buttonOnClick} style={{width: width, padding: Padding,lineHeight:LineHeight,fontSize:FontSize}} type={buttonType}>{label}</Button>
        )
    }
    return (
        <div>
            {type === 'add' && addButton()}
            {type === 'transit' && transitButton()}
            {type === 'accept' && acceptButton()}
            {type === 'outlinedAccept' && outlinedAcceptButton()}
            {type === 'outlinedCancelledButton' && outlinedCancelledButton()}
            {type === 'shipmentTrackingStatus' && shipmentTrackingStatus()}
        </div>
    );
}
