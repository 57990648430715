const initialState = {
    deleteConfirmation: null,
    CencelAndRefund: null,
    RejectCancellation: null,
}

const viewShipmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DELETE_SHIPMENT":
            return {
                ...state,
                deleteConfirmation: action.payload
            }
        case "POST_CENCEL_AND_REFUND":
            return {
                ...state,
                CencelAndRefund: action.payload
            }
        case "POST_REJECT_CENCELLATION":
            return {
                ...state,
                RejectCancellation: action.payload
            }
        default:
            return state
    }
}

export default viewShipmentReducer;
