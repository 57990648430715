import LTLQuotesIcon from "../../../assets/icons/LTLQuotes";
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import WhereToVoteTwoToneIcon from '@mui/icons-material/WhereToVoteTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import RateReviewTwoToneIcon from '@mui/icons-material/RateReviewTwoTone';
import GppGoodTwoToneIcon from '@mui/icons-material/GppGoodTwoTone';
import React from "react";

export const ViewShipmentSidebarData = [
    {
        name: 'Documents',
        icon: <HistoryEduTwoToneIcon />,
        layout: '/view-shipment-document',
        display: true
    },
    {
        name: 'Information',
        icon: <LTLQuotesIcon />,
        layout: '/view-shipment',
        display: true
    },
    // {
    //     name: 'Notes',
    //     icon: <SummarizeTwoToneIcon />,
    //     display: true
    // },
    {
        name: 'Shipment',
        icon: <ViewInArTwoToneIcon />,
        layout: '/view-shipment-details',
        display: true
    },
    {
        name: 'Tracking',
        icon: <WhereToVoteTwoToneIcon />,
        layout: '/view-shipment-tracking',
        display: true
    },
    {
        name: 'Quote',
        icon: <RateReviewTwoToneIcon />,
        layout: '/view-shipment-quote',
        display: true
    },
    {
        name: 'Insurance',
        icon: <GppGoodTwoToneIcon />,
        layout: '/view-shipment-insurance',
        display: true
    },
    // {
    //     name: 'Attechments',
    //     icon: <AttachmentTwoToneIcon />,
    //     display: true
    // },
]