import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import SimpleAccordion from "../../components/Accordion";
import {
  AddCircleOutline,
  DeleteOutlineOutlined,
  AppRegistrationOutlined,
} from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import CustomButton from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { deletePresetData, getAllPresetCommoditiesData, getViewPreset } from "./action";
import { useNavigate } from "react-router";
import CircularLoader from "../../components/Loader";



export default function PresetCommoditiesTable({length}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const StoreData = useSelector((state)=>state)
    const [deleteId,setDeleteId] = useState();
    const [lastRecord, setLastRecord] = useState(0);
    const [open,setOpen] = useState();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    useEffect(()=>{
        dispatch(getAllPresetCommoditiesData({"start" : lastRecord,"limit" : rowsPerPage}))
    },[page,rowsPerPage])
    const HandleEdit = async(id) => {
        await dispatch(getViewPreset(id));
        await navigate("/preset-commodities-details", {state:{Edit:true}})
    }
    const HandleDelete = async(id) => {
      await dispatch(deletePresetData(id));
      await setOpen(false)
      await dispatch(getAllPresetCommoditiesData({"start" : 0,"limit" : 10}))
    }
    const HandleOpen = (id) => {
      setDeleteId(id);
      setOpen(true)
    }
    const handleChangePage = (event, newPage) => {
      const last = newPage * rowsPerPage;
      setLastRecord(last)
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setLastRecord(0)
      setPage(0);
    };
    
  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - length) : 0;
  
  return (
    <div>
        <SimpleAccordion
          title={"Preset Commodities"}
          titleSize={"25px"}
        >
          <Grid style={{ margin: "10px" }}>
            <CustomButton
              type={"add"}
              icon={<AddCircleOutline />}
              label={"Add New Preset "}
              buttonOnClick={()=>navigate("/preset-commodities-details")}
            />
          </Grid>
          {StoreData?.presetCommoditiesReducer?.allPresetCommodities?.length?<Grid style={{ width: "100%", padding: "10px" }}>
            <TableContainer>
             <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow
                    style={{ height: "46px", backgroundColor: "#F8F8F8" }}
                  >
                    <TableCell
                      align="left"
                      style={{ fontSize: "14px", color: "#6C6C6C" }}
                    >
                      PRESET NAME
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: "14px", color: "#6C6C6C" }}
                    >
                      ACTIONS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {StoreData?.presetCommoditiesReducer?.allPresetCommodities?.map((item, index) => (
                    <TableRow style={{ height: "55px" }}>
                      <TableCell align="left">
                        <Grid>
                          <Typography style={{ fontSize: "14px" }}>
                            {item?.package_title}
                          </Typography>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid style={{ display: "flex", gap: "20px" }}>
                          <Grid
                            onClick={()=>HandleEdit(item?.id)}
                            style={{
                              color: "#009AEB",
                              display: "flex", cursor: "pointer" 
                            }}
                          >
                            <AppRegistrationOutlined
                              style={{ fontSize: "20px" }}
                            />
                            <Typography
                              style={{ fontSize: "14px"}}
                            >
                              Edit
                            </Typography>
                          </Grid>
                          <Grid
                            style={{
                              color: "#F85F00",
                              display: "flex",cursor: "pointer" 
                            }}
                            onClick={()=>HandleOpen(item?.id)}
                          >
                            <DeleteOutlineOutlined
                              style={{ fontSize: "20px" }}
                            />
                            <Typography
                              style={{ fontSize: "14px"}}
                            >
                              Delete
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      className={'tablePage'}
                      count={StoreData?.presetCommoditiesReducer?.allPresetCommoditiesLength}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>: 
            <Grid><h1 style={{ textAlign: "center",  fontSize:"16px" }}>No Preset Commodities Found</h1></Grid>
          }
        </SimpleAccordion>
        <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Preset ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpen(false)}>Cencel</Button>
          <Button
            style={{ color: "red" }}
            onClick={() =>HandleDelete(deleteId)}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
