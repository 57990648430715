const initialState = {
    profileDetails: null
}

const myProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PROFILE_DETAILS": 
            return {
                ...state,
                profileDetails: action.payload
            }
        case "SET_UPDATE_PROFILE_DETAILS": 
        return {
            ...state,
            profileDetails: action.payload
        }
        default:
            return state    
    }
}

export default myProfileReducer;
