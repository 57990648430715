import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import "./accordian.scss";
import CustomButton from "../Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function SimpleAccordion(props) {
  const Matches = useMediaQuery("(max-width:600px)");
  const {
    title,
    label,
    children,
    buttonLabel,
    icon,
    buttonOnClick,
    titleSize,
    defaultExpanded,
    isEnterShipment,
    titleIcon = false,
    onTitleIconClick,
  } = props;

  const handleTitleIconClick = (e) => {
    e.stopPropagation();
    onTitleIconClick && onTitleIconClick();
  };
  return (
    <div style={{ width: "100%", margin: "20px 0" }}>
      <Accordion
        defaultExpanded={defaultExpanded ? false : true}
        style={{ width: "100%", borderRadius: "18px" }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              style={{
                backgroundColor: "#f8f8f8",
                borderRadius: "8px",
                width: "40px",
                height: "40px",
                padding: "7px",
                color: "#1b3b61",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ paddingRight: "27px", paddingLeft: "25px" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={12} md={8} xl={8}>
                <Typography
                  variant={"h6"}
                  style={{ fontWeight: "bold", fontSize: titleSize }}
                >
                  {title}{" "}
                  {titleIcon && (
                    <InfoOutlinedIcon
                      onClick={handleTitleIconClick}
                      style={{
                        fontSize: "25px",
                        position: "relative",
                        bottom: "2px",
                      }}
                    />
                  )}
                </Typography>
                <Typography
                  className="accordion-label"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  {label}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={2.9}
                xl={2.9}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: "10px",
                  marginTop: "12px",
                }}
              >
                {buttonLabel && (
                  <div>
                    <CustomButton
                      type={"add"}
                      icon={icon}
                      label={buttonLabel}
                      buttonOnClick={buttonOnClick}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </AccordionSummary>
        <Divider style={{ margin: "0 25px" }} />
        <AccordionDetails
          style={isEnterShipment && Matches ? { padding: "0px" } : {}}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
