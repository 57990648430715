import { useEffect, useState } from 'react';
import { useNavigate, useParams} from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { postEnterShipmentDetails } from './action';
import { useMediaQuery } from '@mui/material';

export default function QuoteAcceptedHooks() {
    const [activeStep, setActiveStep] = useState();
    const dispatch = useDispatch();
    const mobileMatch = useMediaQuery('(max-width:500px)');
    const params = useParams(); 
    const navigate = useNavigate()
    const storeData = useSelector(state=>state);
    const [loading, setLoading] = useState(false);
    const AcceptQuoteData = storeData?.AcceptQuoteReducer?.EnterShipmentDetails;
    const message = storeData?.AcceptQuoteReducer?.ErrorMessage
    useEffect(() => {
      (async() => {
        setLoading(true)
       if(params?.id){ await dispatch(postEnterShipmentDetails({
          shipment_id: params.id
      }))}
      setLoading(false)
      })() 
    }, []);
  
    const handleNext = (index) => {
        setActiveStep(index);
      };
    
      const newQuote = () => {
        navigate('/shipment-detail')
      };

      const shipmentDetail = async() => {
        await dispatch(postEnterShipmentDetails({
            shipment_id: AcceptQuoteData?.shipment?.id
        }))
        await navigate(`/shipment-address/${AcceptQuoteData?.shipment?.id}`);
      };

  const density = (weight,height,width,length,unit)=>{
    const weightt = Number(weight);
    const handlingUnit = Number(unit);
    const cubicFeet = (Number(height) * Number(width) * Number(length))/1728;
    const Density = (weightt / (cubicFeet*handlingUnit)).toFixed(2);
    return Density
  }
  return {
    activeStep,
    AcceptQuoteData,
    handleNext,
    newQuote,
    shipmentDetail,
    loading,
    message,
    density,
    mobileMatch
  };
}