import { api, apiEndPoints } from "../../api";

export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_ALL_SHIPMENTS = "GET_ALL_SHIPMENTS";
export const POST_VIEW_SHIPMENTS = `POST_VIEW_SHIPMENTS`;
export const GET_FILTER_DATA = `GET_FILTER_DATA`;
export const GET_FILTER_SHIPMENTS = `GET_FILTER_SHIPMENTS`;
export const GET_ALL_SHIPMENTS_LENGHT = `GET_ALL_SHIPMENTS_LENGHT`;
export const GET_ALL_CARRIERS = `GET_ALL_CARRIERS`;

const setWalletCurrentBalance = (payload) => {
  return {
    type: GET_WALLET_BALANCE,
    payload: payload.data.availableBalance,
  };
};

export const getWalletCurrentBalance = () => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getWalletBalance());
    dispatch(setWalletCurrentBalance(response));
  } catch (error) {
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("BEARER_TOKEN");
    sessionStorage.clear()
    const { response: { data = {} } = {} } = error;
    window.location = `${process.env.REACT_APP_PARCEL_BASE_URL}/login?redirect=${window.location.href}`;
    return data;
  }
};

const postViewInformation = (payload) => {
    return{
        type: POST_VIEW_SHIPMENTS,
        payload: payload.shipment,
    }
};

const getShipmentsData = (payload) => {
  let Payload ;
  payload?.data?.data?Payload = payload.data.data:Payload = null
  return {
    type: GET_ALL_SHIPMENTS,
    payload: Payload,
  };
};

const getShipmentsLenght = (payload) => {
  let Payload ;
  payload?.data?Payload = payload.data:Payload = null
  return {
    type: GET_ALL_SHIPMENTS_LENGHT,
    payload: Payload,
  };
};

const getFilteredData = (payload) => {
  return {
    type: GET_FILTER_SHIPMENTS,
    payload: payload.filterdetails,
  };
};

const getFilterdata = (payload) => {
  return {
    type: GET_FILTER_DATA,
    payload: payload,
  };
};
const allCarriers = (payload) => {
  return {
    type: GET_ALL_CARRIERS,
    payload: payload,
  };
};

export const getShipmentData = (data) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.getShipmentDatas(),data);
    dispatch(getShipmentsData(response));
    dispatch(getShipmentsLenght(response));
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};

export const getFilterData = () => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.postFilterData());
    dispatch(getFilterdata(response?.data));
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};

export const getInformation = (data,navigate) => async (dispatch) => {
    try {
        const response = await api.post(
            apiEndPoints.postInformationApi(),data
        );
        await dispatch(postViewInformation(response.data))
        navigate(`/view-shipment/${data?.shipment_id}`,{state:{shipment_id:data?.shipment_id}});
    } catch (error) {
        const { response: { data = {} } = {} } = error;
        return data;
    }
}

export const filterShipment = (data) => async (dispatch) => {
    try {
        const response = await api.get(
            apiEndPoints.getFilteredData(data)
        );
        dispatch(getFilteredData(response.data))
    } catch (error) {
        const { response: { data = {} } = {} } = error;
        return data;
    }
}

export const getAllCarriers = () => async (dispatch) => {
    try {
        const response = await api.get(
            apiEndPoints.getAllCarriers()
        );
        dispatch(allCarriers(response.data))
    } catch (error) {
        const { response: { data = {} } = {} } = error;
        return data;
    }
}
