import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Close } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import "./index.scss";
import RadioButton from "../radioButton";
import CustomButton from "../Button";
import { useState } from "react";

export default function DialogBox(props) {
  const { open, onClose, data, setOpen, upgradesServices } = props;
  const [upgrades, setUpgrade] = useState();
  const snakeToCamel = (s) =>
    s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
      c ? c.toUpperCase() : " " + d.toUpperCase()
    );
  const AddUpgrade = () => {
    //   upgrades?setData(upgrades):setData(upgradesServices)
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {data?.origin_method || data?.transit_calendar || data?.no_details ? (
          <div style={{ borderRadius: "28px" }}>
            <div className="box-heading-container">
              <DialogTitle
                id="alert-dialog-title"
                style={{ display: "inline-flex" }}
              >
                Transit Method -{" "}
                {data?.CarrierCode &&
                  data?.CarrierCode?.replace("_", "")?.toUpperCase()}{" "}
                {data?.fromZipcode} to {data?.toZipcode}
              </DialogTitle>
              <Button onClick={handleClose} style={{ color: "black" }}>
                <Close />
              </Button>
            </div>
            <Divider color={"grey"} orientation={"horizontal"} />
            <DialogContent>
              {data?.no_details ? (
                <DialogContentText id="alert-dialog-description">
                  No Transit Detais Found
                </DialogContentText>
              ) : (
                <div>
                  <Card sx={{ backgroundColor: "#FAFAFA" }}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Transit Calendar
                      </Typography>
                      <Divider color={"grey"} orientation={"horizontal"} />
                      <Grid
                        container
                        sx={{ width: "100%", display: "flex" }}
                        margin="10px 0px 10px 0px"
                      >
                        <Grid item xs={12} lg={3.2}>
                          <Typography
                            style={{
                              color: "darkgrey",
                              marginBottom: "8px",
                              fontSize: "13px",
                            }}
                          >
                            CALENDAR DAYS
                          </Typography>
                          <Typography>
                            {data?.transit_calendar?.calendar_days}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography
                            style={{
                              color: "darkgrey",
                              marginBottom: "8px",
                              fontSize: "13px",
                            }}
                          >
                            ESTIMATED DELIVERY
                          </Typography>
                          <Typography>
                            {data?.transit_calendar?.estimated_delivery_date?.slice(
                              5,
                              10
                            ) +
                              "-" +
                              data?.transit_calendar?.estimated_delivery_date?.slice(
                                0,
                                4
                              )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Typography
                            style={{
                              color: "darkgrey",
                              marginBottom: "8px",
                              fontSize: "13px",
                            }}
                          >
                            PICKUP DATE
                          </Typography>
                          <Typography>
                            {data?.transit_calendar?.pickup_date?.slice(5, 10) +
                              "-" +
                              data?.transit_calendar?.pickup_date?.slice(0, 4)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card style={{ marginTop: "20px" }}>
                    <TableContainer>
                      <Table
                        className="transit-calendar"
                        sx={{ minWidth: 500 }}
                        aria-labelledby="tableTitle"
                      >
                        <TableHead>
                          <TableRow style={{ backgroundColor: "#D3D3D3" }}>
                            <TableCell
                              style={{ color: "darkgrey" }}
                              align="left"
                            >
                              ORIGIN
                            </TableCell>
                            <TableCell
                              style={{ color: "darkgrey" }}
                              align="left"
                            >
                              DESTINATION
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow style={{ backgroundColor: "#FAFAFA" }}>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                TRANSIT METHOD
                              </div>
                              <div>
                                {data?.origin_method ? data?.origin_method : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                TRANSIT METHOD
                              </div>
                              <div>
                                {data?.destination_method
                                  ? data?.destination_method
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                TERMINAL
                              </div>
                              <div>
                                {data?.origin_terminal?.terminal_name
                                  ? data?.origin_terminal?.terminal_name
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                TERMINAL
                              </div>
                              <div>
                                {data?.destination_terminal?.terminal_name
                                  ? data?.destination_terminal?.terminal_name
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                ADDRESS
                              </div>
                              <div>
                                {data?.origin_terminal?.address1
                                  ? data?.origin_terminal?.address1
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                ADDRESS
                              </div>
                              <div>
                                {data?.destination_terminal?.address1
                                  ? data?.destination_terminal?.address1
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                COUNTRY
                              </div>
                              <div>
                                {data?.origin_terminal?.country_code
                                  ? data?.origin_terminal?.country_code
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                COUNTRY
                              </div>
                              <div>
                                {data?.destination_terminal?.country_code
                                  ? data?.destination_terminal?.country_code
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT NAME
                              </div>
                              <div>
                                {data?.origin_terminal?.contact_name
                                  ? data?.origin_terminal?.contact_name
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT NAME
                              </div>
                              <div>
                                {data?.destination_terminal?.contact_name
                                  ? data?.destination_terminal?.contact_name
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT TITLE
                              </div>
                              <div>
                                {data?.origin_terminal?.contact_title
                                  ? data?.origin_terminal?.contact_title
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT TITLE
                              </div>
                              <div>
                                {data?.destination_terminal?.contact_title
                                  ? data?.destination_terminal?.contact_title
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT EMAIL
                              </div>
                              <div>
                                {data?.origin_terminal?.contact_email
                                  ? data?.origin_terminal?.contact_email
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT EMAIL
                              </div>
                              <div>
                                {data?.destination_terminal?.contact_email
                                  ? data?.destination_terminal?.contact_email
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT PHONE
                              </div>
                              <div>
                                {data?.origin_terminal?.contact_phone
                                  ? data?.origin_terminal?.contact_phone
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT PHONE
                              </div>
                              <div>
                                {data?.destination_terminal?.contact_phone
                                  ? data?.destination_terminal?.contact_phone
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT TOLL FREE
                              </div>
                              <div>
                                {data?.origin_terminal?.contact_toll_free
                                  ? data?.origin_terminal?.contact_toll_free
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  color: "darkgrey",
                                  marginBottom: "8px",
                                }}
                              >
                                CONTACT TOLL FREE
                              </div>
                              <div>
                                {data?.destination_terminal?.contact_toll_free
                                  ? data?.destination_terminal
                                      ?.contact_toll_free
                                  : ""}
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </div>
              )}
            </DialogContent>
          </div>
        ) : data?.upgrades || data?.upgrades == 0 ? (
          <div>
            <DialogTitle id="alert-dialog-title">
              <span className="upgrade">{"Upgrades"}</span>
            </DialogTitle>
            <Divider color={"grey"} orientation={"horizontal"} />
            <RadioButton
              item={data?.data}
              xs={12}
              lg={1.8}
              setState={setUpgrade}
              upgradesServices={upgradesServices}
              index={data?.upgrades}
              state={data?.value}
            />
            <div
              className="edit-ltl-quotes"
              style={{
                display: "flex",
                width: "42%",
                justifyContent: "space-around",
                margin: "13px 10px",
              }}
            >
              <CustomButton
                type={"add"}
                label={"Add Upgrade"}
                padding={"10px"}
                buttonOnClick={AddUpgrade}
              />
              <CustomButton
                type={"outlinedCancelledButton"}
                label={"Cancel"}
                padding={"10px"}
                buttonOnClick={handleClose}
              />
            </div>
          </div>
        ) : (
          <div style={{ borderRadius: "18px" }}>
            {Array.isArray(data) ? (
              <div>
                <DialogTitle id="alert-dialog-title">
                  {"Accessorial Charges"}
                </DialogTitle>
                <Divider orientation={"horizontal"} />
                <DialogContent className="accessorial-box">
                  {data?.length === 0 ? (
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        <DialogContentText id="alert-dialog-description">
                          No Accessorial Selected
                        </DialogContentText>
                      </CardContent>
                    </Card>
                  ) : (
                    <div>
                      <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                          {data?.length &&
                            data?.map((item, key) => (
                              <DialogContentText id="alert-dialog-description">
                                <p
                                  style={{
                                    fontWeight: "600",
                                    display: "inline-block",
                                  }}
                                >
                                  {item?.accessorial_type
                                    ? snakeToCamel(item.accessorial_type)
                                    : item.accessorial_type}
                                </p>
                                : ${item.cost.toFixed(2)} USD
                              </DialogContentText>
                            ))}
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    autoFocus
                    style={{ color: "#f85f00" }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </div>
            ) : (
              <div className="loader">
                <CircularProgress color="warning" />
              </div>
            )}
          </div>
        )}
      </Dialog>
    </div>
  );
}
