const initialState = {
    Cargoshipment: null,
    CargoCertificate: null,
}

const CargoShipmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "POST_CARGO_DATA":
            return {
                ...state,
                Cargoshipment: action.payload
            }
        case "POST_CARGO_CERTIFICATE":
            return {
                ...state,
                CargoCertificate: action.payload
            }
        default:
            return state
    }
}

export default CargoShipmentReducer;
