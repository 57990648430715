import { toast } from "react-toastify";
import { api, apiEndPoints } from "../../api";

export const GET_QUOTE_DETAILS = `GET_QUOTE_DETAILS`;
export const POST_RATE_OF_CARRIER = `POST_RATE_OF_CARRIER`;
export const POST_TRANSIST_DATA = `POST_TRANSIST_DATA`;
export const POST_QUOTE_ACCEPT = `POST_QUOTE_ACCEPT`;
export const POST_QUOTE_INSURANCE = `POST_QUOTE_INSURANCE`;
export const POST_RERENDER = `POST_RERENDER`;
export const GET_QUOTE_DETAILS_ALL = `GET_QUOTE_DETAILS_ALL`;
export const POST_CLEAR_CARRIER_DATA = `POST_CLEAR_CARRIER_DATA`;
export const POST_RATE_OF_FAIL_CARRIER = `POST_RATE_OF_FAIL_CARRIER`;
export const POST_RATE_OF_FAIL_CARRIER_MESSAGE = `POST_RATE_OF_FAIL_CARRIER_MESSAGE`;
export const GET_RATE_OF_CARRIER_FAIL = `GET_RATE_OF_CARRIER_FAIL`;
export const CLEAR_ALL_DATA = `CLEAR_ALL_DATA`;

const getQuote = (payload) => {
  return {
    type: GET_QUOTE_DETAILS,
    payload: payload.data.carriers,
  };
};
const postClearData = (payload) => {
  return {
    type: POST_CLEAR_CARRIER_DATA,
    payload: null,
  };
};
export const clearQuoteData = () => {
  return {
    type: CLEAR_ALL_DATA,
    payload: null,
  };
};
const getQuoteDetailsAllData = (payload) => {
  return {
    type: GET_QUOTE_DETAILS_ALL,
    payload: payload.data,
  };
};
export const reRender = (payload) => {
  return {
    type: POST_RERENDER,
    payload: payload,
  };
};
const postRateCarrier = (payload, name) => {
  payload.carrier_name = name;
  return {
    type: POST_RATE_OF_CARRIER,
    payload: payload,
  };
};
const postRateFailCarrier = (payload) => {
  return {
    type: POST_RATE_OF_FAIL_CARRIER,
    payload: payload,
  };
};
const postRateFailCarrierMesage = (payload) => {
  return {
    type: POST_RATE_OF_FAIL_CARRIER_MESSAGE,
    payload: payload,
  };
};
const getTransist = (payload) => {
  return {
    type: POST_TRANSIST_DATA,
    payload: payload,
  };
};
const postAccept = (payload) => {
  return {
    type: POST_QUOTE_ACCEPT,
    payload: payload,
  };
};
const postInsurence = (payload) => {
  return {
    type: POST_QUOTE_INSURANCE,
    payload: payload.cargoQuoteData,
  };
};
const postInsurenceClear = () => {
  return {
    type: POST_QUOTE_INSURANCE,
    payload: null,
  };
};

const getRateOfCarrierFail = (payload) => {
  return {
    type: GET_RATE_OF_CARRIER_FAIL,
    payload: payload,
  };
};

export const getQuoteDetailsData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getQouteData(id));
    dispatch(getQuoteDetailsAllData(response));
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};

export const getQuoteDetails = (data) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.getQuoteDetails(), data);
    dispatch(getQuote(response));
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};

export const postRateOfCarrier = (data, name) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.postRateCarrier(), data);
    if (response.status) {
      dispatch(
        postRateCarrier(
          {
            ...response?.data?.carrierRates,
            transitDetails: response?.data?.transitDetails || {},
          },
          name
        )
      );
    } else {
      let failCarrierData = {
        [name]: response?.response?.data?.message,
      };
      dispatch(getRateOfCarrierFail(failCarrierData));
      dispatch(postRateFailCarrier(data?.carrier_id));
      dispatch(postRateFailCarrierMesage(response?.response?.data?.message));
    }
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};

export const getTransistData = (data, setDialogData) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.postGetCarrier(), data);
    await dispatch(getTransist(response?.data));
    if (!setDialogData) {
      return response.data;
    } else {
      await setDialogData(
        response?.data?.TransitData?.origin_method
          ? { ...response?.data?.TransitData, ...response?.data }
          : { no_details: response?.data?.TransitData }
      );
    }
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};

export const postAcceptQuote = (data) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.postQuoteAccept(), data);
    await dispatch(postAccept(response?.data));
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};
export const postQuoteInsurence = (data) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.postCargoInsurence(), data);
    if (response.status) {
      await dispatch(postInsurence(response?.data));
    } else {
      toast.error(
        "The Cargo insurance API is not responding at this time, please try again later. You proceed without cargo insurance.",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        }
      );
    }
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};

export const postClearInsurance = () => async (dispatch) => {
  dispatch(postInsurenceClear());
};
export const postClearCarrierData = () => async (dispatch) => {
  dispatch(postClearData());
};

export const getCarrierAllFail = (data) => async (dispatch) => {
  try {
    await api.post(apiEndPoints.getCarrierRateFailAll(), data);
  } catch (error) {
    const { response: { data = {} } = {} } = error;
    return data;
  }
};
