const initialState = {
    userData: null,
    pickupServices: null,
    deleveryServices: null,
    handlingServices: null,
    saveInfo: null,
    remainingAttempts: null,
}

const quickrateReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PICKUP_SERVICES":
            return {
                ...state,
                pickupServices: action.payload
            }
        case "GET_DELIVERY_SERVICES":
            return {
                ...state,
                deleveryServices: action.payload
            }
        case "GET_HANDLING_SERVICES":
            return {
                ...state,
                handlingServices: action.payload
            }
        case "POST_SAVE_INFO":
            return {
                ...state,
                saveInfo: action.payload
            }
        case "POST_REMAINING_ATTEMPT_INFO":
            return {
                ...state,
                remainingAttempts: action.payload
            }
        default:
            return state
    }
}

export default quickrateReducer;