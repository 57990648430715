import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.scss";
import QuoteAccepted from "../QuoteAccepted";
import ViewQuoteHook from "./ViewQuoteHook";
import ViewShipmentDrawer from "../../components/ViewShipmentDrawer";
import BackToShipment from "../../components/BackToShipment";
import CircularLoader from "../../components/Loader";

const shipmentDrawerWidth = 240;

export default function ViewQuote(props) {
  const {
    loadingData,
    params,
    matches,
    radiusMatches
  } = ViewQuoteHook(props);

  return (
    <>
      {loadingData ? (<CircularLoader/>) : (    
      <div style={{width:"100%"}}>
      {matches?<ViewShipmentDrawer name={"Quote"} shipment_id={params?.id} />:""}
      <div className={!radiusMatches?"background-component":""} style={{ backgroundColor: "#fff", position: 'relative'}}>
          <Box
            sx={{
              marginLeft: { xs: "0%", md: "3%", xl: "2%" },
              marginTop: { xs: "20px", md: "20px", xl: "20px" },
            }}
          >
            <Box sx={{ flexGrow: 1, width: "100%" }}>
              <Grid
                container
                spacing={2}
                sx={{ width: "100%" }}
                className="view-shipment-title"
              >
                <Grid item xs={12} xl={12}>
                  <Typography variant={"h6"} style={{ fontWeight: "800" }}>
                    View Shipment
                  </Typography>
                  <Typography className="accordion-label">
                    View selected shipment details
                  </Typography>
                </Grid>
              </Grid>
              <Divider className="divider_viewshipment"/>
              <Box>
                <Box
                  component="nav"
                  sx={{
                    width: { sm: shipmentDrawerWidth },
                    flexShrink: { sm: 0 },
                  }}
                  aria-label="mailbox folders"
                >
                  <BackToShipment />
                </Box>
              </Box>
              <Box sx={{ display: "flex" }} className="info-box">
                <CssBaseline />
                <Box
                  component="main"
                  sx={matches?{
                    flexGrow: 1,
                    bgcolor: "background.default",
                  }:{
                    flexGrow: 1,
                    bgcolor: "background.default",
                    p: 3,
                    width: { sm: `calc(100% - ${shipmentDrawerWidth}px)` },
                    marginLeft: "112px",
                  }}
                >
                  {/* <ViewShipmentDrawer name={"Quote"} shipment_id={params?.id} /> */}
                  {!matches?<ViewShipmentDrawer name={"Quote"} shipment_id={params?.id} />:""}
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "100%" }}
                    style={{ marginBottom: "10px" }}
                  ></Grid>
                  <QuoteAccepted AddressShipment={true} ViewQuote={true} />
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
        </div>
      )}
    </>
  );
}
