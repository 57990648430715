const initialState = {
    generalDetails: null,
    generalUserData: null,
    userType:null
}

const generalDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_GENERAL_DETAILS": 
            return {
                ...state,
                generalDetails: action.payload
            }
        case "GET_GENERAL_USER_DATA": 
            return {
                ...state,
                generalUserData: action.payload
            }
        case "GET__USER_TYPE_GENERAL": 
            return {
                ...state,
                userType: action.payload
            }
        default:
            return state
    }
}

export default generalDetailReducer;
