import React, { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import ViewShipmentDetails from "../pages/View-Shipments";
import ViewQuote from '../pages/ViewQuote';
import PresetCommoditiesTable from '../pages/PresetCommodities/Table';
import CircularLoader from '../components/Loader';
const ShipmentDetail = lazy(() => import('../pages/ShipmentDetails'));
const FastTracking = lazy(() => import('../pages/FastOrderTracking'));
const Quote = lazy(() => import('../pages/Quote'));
const QuoteAccepted = lazy(() => import('../pages/QuoteAccepted'));
const ShipmentAddress = lazy(() => import('../pages/ShipmentAddress'));
const AddressBook = lazy(() => import('../pages/AddressBook'));
const Profile = lazy(() => import('../pages/Profile'));
const MyWallet = lazy(() => import('../pages/MyWallet'));
const PaymentTypes = lazy(() => import('../pages/PaymentTypes'));
const PaymentTypesCard = lazy(() => import('../pages/PaymentTypesCard'));
const GeneralSetting = lazy(() => import('../pages/GeneralSettings'));
const Shipments = lazy(() => import('../pages/Shipments'));
const ViewShipment = lazy(() => import('../pages/ViewShipment'));
const Addaddress = lazy(()=> import("../pages/Addaddress"))
const ViewDocument = lazy(()=> import("../pages/ViewShipmentsDocument"));
const ViewCargo = lazy(()=> import("../pages/ViewShipmentCargo"));
const ViewShipmentTracking = lazy(()=> import('../pages/ViewShipmentsTracking'));
const Support = lazy(() => import('../pages/Support'));
const QuickRateQuote = lazy(() => import('../pages/QuickRateQuote'));
const GuestQuote = lazy(() => import('../pages/GuestQuotes'));
const FAQPage = lazy(() => import('../pages/FAQPage'));
const GuestQuoteLanding = lazy(() => import('../pages/QuoteLandingPage'));
const PresetCommodities = lazy(()=> import("../pages/PresetCommodities"))
const AppRoutes = () => {
    
    const element = useRoutes([
        { path: "/", element: <Shipments /> },
        { path: "/view-shipment/:id", element: <ViewShipment /> },
        { path: "/faq-page", element: <FAQPage /> },
        { path: "/guest-quote", element: <QuickRateQuote /> },
        { path: "/fast-tracking/:id", element: <FastTracking /> },
        { path: "/guest-quotes-details", element: <GuestQuote /> },
        { path: "/support", element: <Support /> },
        { path: "/view-shipment-details/:id", element: <ViewShipmentDetails/> },
        { path: "/view-shipment-insurance/:id", element: <ViewCargo/> },
        { path: "/view-shipment-tracking/:id", element: <ViewShipmentTracking/> },
        { path: "/view-shipment-quote/:id", element: <ViewQuote/> },
        { path: "/view-shipment-document/:id", element: <ViewDocument/> },
        { path: "/shipment-detail", element: <ShipmentDetail /> },
        { path: "/ltl-quotes/:id", element: <Quote /> },
        { path: "/quote-accept/:id", element: <QuoteAccepted /> },
        { path: "/shipment-address/:id", element: <ShipmentAddress /> },
        { path: "/address-book", element: <AddressBook /> },
        { path: "/profile", element: <Profile /> },
        { path: "/my-wallet", element: <MyWallet /> },
        { path: "/setting/payment-type", element: <PaymentTypesCard /> },
        { path: "/setting/payment-type-cards", element: <PaymentTypes /> },
        { path: "/setting/general-settings", element: <GeneralSetting /> },
        { path : "/address-book/Add-address" , element : <Addaddress  /> },
        { path: "/guest-quote-landing", element: <GuestQuoteLanding /> },
        { path: "/preset-commodities-details", element: <PresetCommodities /> },
        { path: "/preset-commodities", element: <PresetCommoditiesTable /> },

    ]);
    return(
        <Suspense
            fallback={<CircularLoader/>}
        >
            {element}
        </Suspense>
    )
}

export default AppRoutes
