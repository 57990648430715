const initialState = {
    PublicTrackingLink: null,
    TrackingData: null,
    TrackUpdatedData: null
}

const ShipmentTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "POST_TRACKING_LINK":
            return {
                ...state,
                PublicTrackingLink: action.payload
            }
        case "POST_TRACKING_DATA":
            return {
                ...state,
                TrackingData: action.payload
            }
        case "POST_TRACK_NOW_RESET":
            return {
                ...state,
                TrackUpdatedData: null
            }
        case "POST_TRACK_NOW":
            return {
                ...state,
                TrackUpdatedData: action.payload
            }
        default:
            return state
    }
}

export default ShipmentTrackingReducer;
