import FileSaver from "file-saver";
import { api } from "../api";
import * as XLSX from "xlsx";
import isEmpty from "lodash/isEmpty";
import fxfe_t from "../assets/CarriersLogos/fxfe_t.png";
import abfs_t from "../assets/CarriersLogos/abfs_t.png";
import rdwy_t from "../assets/CarriersLogos/rdwy_t.png";
import saia_t from "../assets/CarriersLogos/saia_t.png";
import sefl_t from "../assets/CarriersLogos/sefl_t.png";
import tfin_t from "../assets/CarriersLogos/tfin_t.png";
import aact_t from "../assets/CarriersLogos/aact_t.png";
import oakh_t from "../assets/CarriersLogos/oakh_t.png";
import ward_t from "../assets/CarriersLogos/ward_t.png";
import clni_t from "../assets/CarriersLogos/clni_t.png";
import cnwy_t from "../assets/CarriersLogos/cnwy_t.png";
import fwda_t from "../assets/CarriersLogos/fwda_t.png";
import mtvl_t from "../assets/CarriersLogos/mtvl_t.png";
import smtl_t from "../assets/CarriersLogos/smtl_t.png";
import pens_t from "../assets/CarriersLogos/pens_t.png";
import numt_t from "../assets/CarriersLogos/numt_t.png";
import cc_t from "../assets/CarriersLogos/cc_t.png";
import rl_t from "../assets/CarriersLogos/rnlo_full.png";
import ppm_l from "../assets/CarriersLogos/ppm_l.png";
import visaCard from "../assets/CreditCardLogo/visa.svg";
import masterCard from "../assets/CreditCardLogo/mastercard.svg";
import americanExpress from "../assets/CreditCardLogo/american-express.svg";
import defaultCard from "../assets/CreditCardLogo/credit-card-default.png";
import rdfs_t from "../assets/CarriersLogos/rdfs_t.png";
import gtjn_t from "../assets/CarriersLogos/gtjn_t.png";
import hrcf_t from "../assets/CarriersLogos/hrcf_t.png";
import avrt_t from "../assets/CarriersLogos/avrt_t.png";

export const downloadFile = async (url, fileName = "download") => {
  const blob = await api.get(url).then((r) => r.blob());
  FileSaver.saveAs(blob, fileName);
};

export const getQueryParams = (data) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};

export const exportToCSV = (
  headerObj = {}, // must contains table Headers Name with there respective keys
  dataArr = [], // must be an array of objects
  fileName = "download" // file Name
) => {
  if (!isEmpty(dataArr) && !isEmpty(headerObj)) {
    const mappedLogs = dataArr.map((row) => {
      const temp = {};
      Object.entries(row).map(
        (entity) => (temp[headerObj[entity[0]]] = entity[1])
      );
      return { ...temp };
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(mappedLogs);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
};

export const getCarrierLogo = (id) => {
  let carriersData = [
    {
      id: 1,
      name: "FedEx Freight Priority",
      src: fxfe_t,
    },
    {
      id: 11,
      name: "ABF Freight",
      src: abfs_t,
    },
    {
      id: 20,
      name: "YRC",
      src: rdwy_t,
    },
    {
      id: 21,
      name: "Saia",
      src: saia_t,
    },
    {
      id: 22,
      name: "Southeastern Freight Lines",
      src: sefl_t,
    },
    {
      id: 24,
      name: "TForce Freight",
      src: tfin_t,
    },
    {
      id: 1101,
      name: "AAA Cooper / MME",
      src: aact_t,
    },
    {
      id: 3093,
      name: "Ward Trucking",
      src: ward_t,
    },
    {
      id: 3555,
      name: "Clear Lane Freight",
      src: clni_t,
    },
    {
      id: 3772,
      name: "XPO Logistics",
      src: cnwy_t,
    },
    {
      id: 159470,
      name: "ForwardAir (Alt)",
      src: fwda_t,
    },
    {
      id: 155216,
      name: "Southwestern Motor Transport",
      src: smtl_t,
    },
    {
      id: 130937,
      name: "GLS Freight",
      src: mtvl_t,
    },
    {
      id: 192832,
      name: "Numark Transportation",
      src: numt_t,
    },
    {
      id: 154575,
      name: "Cross Country",
      src: cc_t,
    },
    {
      id: 146021,
      name: "Peninsula Truck",
      src: pens_t,
    },
    {
      id: 2,
      name: "Roadrunner",
      src: rdfs_t,
    },
    {
      id: 1859,
      name: "Oak Harbor",
      src: oakh_t,
    },
    {
      id: 248952,
      name: "Go2 Logistics",
      src: gtjn_t,
    },
    {
      id: 19,
      name: "RL Carriers",
      src: rl_t,
    },
    {
      id: 278525,
      name: "TForce Freight 2.0",
      src: tfin_t,
    },
    {
      id: 278908,
      name: "Hercules Forwarding",
      src: hrcf_t,
    },
    {
      id: 250417,
      name: "Averitt Express (Dynamic Pricing)",
      src: avrt_t,
    },
  ];
  let logoData = carriersData.find((i) => Number(i.id) === Number(id));
  let logoSrc = logoData ? logoData.src : ppm_l;
  return logoSrc;
};

export const getCreditCardLogo = (type) => {
  let CreditCardData = [
    {
      type: "Visa",
      src: visaCard,
    },
    {
      type: "MasterCard",
      src: masterCard,
    },
    {
      type: "AmericanExpress",
      src: americanExpress,
    },
  ];
  let logoData = CreditCardData.find((i) => i.type === type);
  let logoSrc = logoData ? logoData.src : defaultCard;
  return logoSrc;
};

export const convertTimeToDropdown = (time) => {
  const hours = time?.slice(0, 2);
  const minutes = time?.slice(2, 4);
  return `${hours}:${minutes}`;
};

export function calculateDensity(length, width, height, weight, units) {
  if (!length || !width || !height || !weight || !units) {
    return 0;
  }
  // Convert dimensions from inches to feet
  const lengthFt = length / 12;
  const widthFt = width / 12;
  const heightFt = height / 12;

  // Calculate volume in cubic feet
  const volume = lengthFt * widthFt * heightFt;

  // Calculate density in lb/ft³
  let density = weight / volume / units;

  // Round density to 3 decimal places
  density = density.toFixed(2);

  return density;
}
