import React from 'react';
import PropTypes from 'prop-types';

export default function ShipmentsIcon({ fill, sx }) {
    return (
        <svg id="vuesax_bulk_element-3" data-name="vuesax/bulk/element-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill} style={sx}>
            <g id="element-3">
                <path id="Vector" d="M8.5,6.52V1.98C8.5.57,7.86,0,6.27,0H2.23C.64,0,0,.57,0,1.98V6.51C0,7.93.64,8.49,2.23,8.49H6.27C7.86,8.5,8.5,7.93,8.5,6.52Z" transform="translate(13.5 2)" opacity="0.4" />
                <path id="Vector-2" data-name="Vector" d="M8.5,6.27V2.23C8.5.64,7.86,0,6.27,0H2.23C.64,0,0,.64,0,2.23V6.27C0,7.86.64,8.5,2.23,8.5H6.27C7.86,8.5,8.5,7.86,8.5,6.27Z" transform="translate(13.5 13.5)" />
                <path id="Vector-3" data-name="Vector" d="M8.5,6.52V1.98C8.5.57,7.86,0,6.27,0H2.23C.64,0,0,.57,0,1.98V6.51C0,7.93.64,8.49,2.23,8.49H6.27C7.86,8.5,8.5,7.93,8.5,6.52Z" transform="translate(2 2)" />
                <path id="Vector-4" data-name="Vector" d="M8.5,6.27V2.23C8.5.64,7.86,0,6.27,0H2.23C.64,0,0,.64,0,2.23V6.27C0,7.86.64,8.5,2.23,8.5H6.27C7.86,8.5,8.5,7.86,8.5,6.27Z" transform="translate(2 13.5)" opacity="0.4" />
                <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
            </g>
        </svg>


    );
}

ShipmentsIcon.propTypes = {
    fill: PropTypes.string,
    sx: PropTypes.object,
};

ShipmentsIcon.defaultProps = {
    fill: '#4d4663',
    sx: {},
};
