import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Collapse,
  CssBaseline,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { AddCircleOutline } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Routes from "./components/routes";
import "./sidebar.scss";
import CustomButton from "../Button";
import PalletPathLogo from "../../assets/Pallet_Path_Logo_Main.png";
import PalletPathSmallLogo from "../../assets/orange.png";
import ProfileIcon from "../../../src/assets/icons/Profile";
import LogoutIcon from "../../assets/icons/Logout";
import OpenIcon from "../../assets/open.svg";
import CloseIcon from "../../assets/close.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getSideBarState } from "./action";
import { useRef } from "react";
import { clearQuoteData } from "../../pages/Quote/action";

const drawerWidth = 260;
const settings = [
  {
    label: "Logout",
    icon: <LogoutIcon style={{ margin: "0px 10px 0px 0px" }} />,
  },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 34px)`,
  [theme.breakpoints.down(1081)]: {
    top: "60px",
    width: `250px`,
    justifyContent: "space-between",
  },
  [theme.breakpoints.down("sm")]: {
    top: "50px",
    width: `250px`,
  },
  // [theme.breakpoints.only(375)]: {
  //     top: '50px !important'
  // },
  [theme.breakpoints.down(321)]: {
    top: "50px !important",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(100% - calc(84px + 5px))`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down("sm")]: {
    width: `100%`,
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [alignment, setAlignment] = useState("freight");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [menu, setMenu] = useState();
  const matches = useMediaQuery("(max-width:4000px) and (min-width:1081px )");
  const mobilwidth = useMediaQuery("(max-width:1081px) and (min-width:200px)");
  const [mobileMenu, setMobileMenu] = useState(
    useMediaQuery("(max-width:1081px) and (min-width:200px)")
  );
  const [expanded, setExpanded] = useState(false);
  const [routes, setRoutes] = useState(Routes);
  const wrapperRef = useRef(null);
  const myMobile = useMediaQuery("(max-width:600px) and (min-width:200px)");
  const currentBalance = useSelector((state) => state);
  const pathName = location.pathname.split("/");
  const Shipments = [
    "",
    "view-shipment",
    "view-shipment-document",
    "view-shipment-details",
    "view-shipment-tracking",
    "view-shipment-quote",
    "view-shipment-insurance",
  ];
  const LtlQuote = [
    "shipment-detail",
    "ltl-quotes",
    "quote-accept",
    "shipment-address",
  ];
  const General = ["general-settings"];
  const Setting = [
    "address-book",
    "setting",
    "preset-commodities",
    "preset-commodities-details",
  ];
  const PaymentType = ["payment-type", "payment-type-cards"];
  const Preset = ["preset-commodities", "preset-commodities-details"];

  useEffect(() => {
    setMobileMenu(mobilwidth);
  }, [mobilwidth]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (pathName.length) {
      Shipments.includes(pathName[1]) && setMenu("Shipments");
      LtlQuote.includes(pathName[1]) && setMenu("LTL Quotes");
      pathName[1] === "support" && setMenu("Support");
      if (Setting.includes(pathName[1])) {
        routes.filter((item) => {
          if (
            item.name === "General" ||
            item.name === "Payment Types" ||
            item.name === "Preset Commodity" ||
            item.name === "Address Book"
          ) {
            item.display = true;
          }
        });
        General.includes(pathName[2]) && setMenu("General");
        PaymentType.includes(pathName[2]) && setMenu("Payment Types");
        pathName[1] === "address-book" && setMenu("Address Book");
        Preset.includes(pathName[1]) && setMenu("Preset Commodity");
      }
    }
  }, [location]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleOpenUserMenu = (event) => {
    setExpanded(!expanded);
    setAnchorElUser(event.currentTarget);
  };
  const handleParcel = () => {
    const token = JSON.parse(localStorage.getItem("TOKEN"));
    window.location = `${process.env.REACT_APP_PARCEL_BASE_URL}/loginWithToken/${token}`;
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setExpanded(!expanded);
  };

  const handleChange = (event, newAlignment) => {
    newAlignment ? setAlignment(newAlignment) : setAlignment(alignment);
  };

  const navigateWithReload = (placeToGo) => {
    {
      if (window.location.pathname === "/shipment-detail") {
        window.location.reload();
      } else {
        navigate(placeToGo, { replace: true });
      }
    }
  };

  const handleShipmentCreation = () => {
    dispatch(clearQuoteData());
    navigateWithReload("/shipment-detail");
  };

  const handleDrawerClose = () => {
    !mobilwidth && setOpen(!open);
    mobilwidth && setMobileMenu(!mobileMenu);
    dispatch(getSideBarState(!open));
  };
  const ExpandMores = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  const handleLogOut = async () => {
    await localStorage.removeItem("TOKEN");
    await localStorage.removeItem("BEARER_TOKEN");
    await sessionStorage.clear();
    window.location = `${process.env.REACT_APP_PARCEL_BASE_URL}/login`;
  };
  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      mobilwidth && setMobileMenu(true);
    }
  }

  return (
    <Box sx={{ display: "flex" }} ref={wrapperRef}>
      <CssBaseline />
      <AppBar position="fixed" open={matches === true ? open : false}>
        <Toolbar>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={3} md={7} xl={20}>
              <IconButton
                className="sidebar-icon"
                aria-label="open drawer"
                onClick={handleDrawerClose}
                edge="start"
              >
                {mobilwidth ? (
                  mobileMenu ? (
                    <img src={OpenIcon} alt="open icon" width="35px" />
                  ) : (
                    <img src={CloseIcon} alt="close icon" width="35px" />
                  )
                ) : (
                  <MenuIcon />
                )}
              </IconButton>
            </Grid>
            {!mobilwidth ? (
              <>
                <Grid
                  className="balance1"
                  item
                  xs={12}
                  md={3.5}
                  xl={7.5}
                  style={{
                    backgroundColor: "#fbe9e7",
                    color: " #f85f00",
                    gap: 5,
                    padding: "0px 10px 0px 10px",
                    marginTop: "16px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                >
                  <Typography textAlign="center" style={{ lineHeight: "1" }}>
                    Current balance:{" "}
                  </Typography>
                  <BusinessCenterIcon />
                  <Typography style={{ lineHeight: "1" }} textAlign="center">
                    ${" "}
                    {currentBalance.allShipmentsReducer.currentBalance?.toFixed(
                      2
                    )}
                  </Typography>
                </Grid>
              </>
            ) : null}
            <Grid className="balance2 " item xs={6} sm={5} md={3.1} xl={7.5}>
              <CustomButton
                FontSize={mobilwidth && "10px"}
                type={"add"}
                icon={<AddCircleOutline />}
                label={"Create New Shipment"}
                buttonOnClick={handleShipmentCreation}
                Padding={"10px"}
                LineHeight={"1.1"}
              />
            </Grid>
            <Grid item xs={myMobile ? 4 : 3} sm={2} md={2} xl={3.5}>
              <div
                className="profileIcon"
                style={{
                  alignItems: "center",
                  cursor: "pointer",
                  float: "right",
                }}
                onClick={handleOpenUserMenu}
              >
                <ProfileIcon />
                <ExpandMores
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMores>
              </div>

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Link
                        to={setting.link}
                        style={{
                          display: "flex",
                          color: "#000",
                          textDecoration: "none",
                        }}
                        onClick={() =>
                          setting?.label === "Logout" ? handleLogOut() : ""
                        }
                      >
                        <Typography textAlign="center">
                          <div style={{ display: "flex" }}>{setting.icon}</div>
                        </Typography>
                        <Typography textAlign="center">
                          <div
                            style={{
                              marginLeft: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {setting.label}
                          </div>
                        </Typography>
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Collapse>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={!mobileMenu && "permanent"}
        className={!mobileMenu ? "mobileWidth drawer" : "drawer"}
        open={mobileMenu ? false : matches === true ? open : false}
      >
        <div>
          <DrawerHeader>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                {open ? (
                  <ListItemIcon style={{ maxWidth: "85%", marginRight: "0px" }}>
                    <img
                      alt="PalletPathLogo"
                      style={{ maxWidth: "100%" }}
                      src={PalletPathLogo}
                    />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon
                    sx={{
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={PalletPathSmallLogo}
                      alt="min screen logo"
                      width={"20%"}
                    />
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          </DrawerHeader>
          <List
            style={{ display: "flex", justifyContent: "center", margin: 7 }}
          >
            {open ? (
              <ToggleButtonGroup
                className="toggle-button"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="freight">Freight</ToggleButton>
                <ToggleButton
                  value="parcel"
                  onClick={() => {
                    handleParcel();
                  }}
                >
                  Parcel
                </ToggleButton>
              </ToggleButtonGroup>
            ) : (
              <ToggleButtonGroup
                className="toggle-button"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="freight">F</ToggleButton>
                <ToggleButton
                  value="parcel"
                  onClick={() => {
                    handleParcel();
                  }}
                >
                  P
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </List>

          <List>
            {routes.map(
              (text, index) =>
                text.display === true && (
                  <ListItem
                    key={index}
                    className={"itemModule"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      style={{ padding: "10px", margin: "5px 20px" }}
                      className={text.name === menu ? "isActive" : ""}
                      onClick={() => {
                        if (text.name === "Settings") {
                          routes.filter((item) => {
                            if (
                              item.name === "General" ||
                              item.name === "Payment Types" ||
                              item.name === "Preset Commodity" ||
                              item.name === "Address Book"
                            ) {
                              item.display = !item.display;
                            }
                          });
                          setRoutes([...routes]);
                          setMenu(text.name);
                        } else {
                          navigate(text.layout);
                          setMenu(text.name);
                        }
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {open ? (
                        <ListItemIcon
                          style={{ marginRight: "5px", marginLeft: "10px" }}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {text.icon}
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon
                          style={{ marginRight: "5px", marginLeft: "5px" }}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {text.icon}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={text.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </div>
        {mobilwidth && (
          <Box className="balance-box">
            <div className="current-balance">
              <Typography textAlign="center" style={{ lineHeight: "1" }}>
                Current balance:{" "}
              </Typography>
              <BusinessCenterIcon />
              <Typography style={{ lineHeight: "1" }} textAlign="center">
                ${" "}
                {currentBalance.allShipmentsReducer.currentBalance?.toFixed(2)}
              </Typography>
            </div>
          </Box>
        )}
      </Drawer>
    </Box>
  );
}
