import React, { useState, useEffect } from "react";
import "rsuite/dist/rsuite.css";
import './App.css';
import MiniDrawer from "./components/sidebar";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Routes from './routes/index'
import ReactGA4 from 'react-ga4';
import zipy from "zipyai";
import {
  api,
  apiEndPoints,
} from './api';
import RouteChangeTracker from './components/googleAnalytics/googleAnalytics'
import {getBearerToken, setBearerToken, setToken } from './utils/auth'
import { useLocation } from 'react-router-dom'
import { getWalletCurrentBalance } from "./pages/Shipments/action";
import { useDispatch } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CacheBuster from './components/CacheBuster/index';
import packageData from '../package.json';
import CircularLoader from "./components/Loader";
export const BEARER_TOKEN = 'BEARER_TOKEN';
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);


function App() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const authToken = params.get("token");
  const [isTokenLoaded, setTokenLoaded] = useState('');
  const dispatch = useDispatch();
  const local = getBearerToken();
  const metaVersion = localStorage.getItem('appVersion')
  const pathName = ['/guest-quote','/guest-quotes-details','/guest-quote-landing','fast-tracking']
  const userCookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
  const userCookieToken = userCookie('user_cookie');

  useEffect(()=>{

    if(authToken){
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("BEARER_TOKEN");
      (async () => {
        try {
          await setToken(authToken)
          const response = await api.post(
            apiEndPoints.getUserToken(authToken),
            );
            await setBearerToken(response.data.token);
            setTokenLoaded(response.data.token);
            if(!metaVersion){
              localStorage.setItem('appVersion', packageData.version);
            }


            

            try {
              const currentUserDataForZippy = await api.get(
                apiEndPoints.getProfileDetail(),
              );  
              if(currentUserDataForZippy.data.user.email != "cole@parcelpath.com"){
                zipy.init('9fc4c33c',{releaseVer: "1.1.1", rootDomain: "parcelpath.com"});

                //zipy integration
                zipy.identify(currentUserDataForZippy.data.user.email, {
                  firstName: currentUserDataForZippy.data.user.first_name,
                  lastName: currentUserDataForZippy.data.user.last_name,
                  email: currentUserDataForZippy.data.user.email,
                  customerName: currentUserDataForZippy.data.user.email
                });
              }
              } catch (error) {
              }
            

            dispatch(getWalletCurrentBalance())
          } catch (error) {
            localStorage.removeItem("TOKEN");
            localStorage.removeItem("BEARER_TOKEN");
            console.log("Not Authorized : Redirect to parcel path")
            sessionStorage.clear()
            window.location = `${process.env.REACT_APP_PARCEL_BASE_URL}/login?redirect=${window.location.href}`;
            return
          }
        })()
    }else if(local){
      if(!metaVersion){
        localStorage.setItem('appVersion', packageData.version);
      }
      dispatch(getWalletCurrentBalance())
      setTokenLoaded(true)

    }else if(userCookieToken && !pathName.includes(window.location.pathname) && !pathName.includes(window.location?.pathname?.split("/")[1] ) ){  
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("BEARER_TOKEN");
      (async () => {
        try {
          await setToken(userCookieToken)
          const response = await api.post(
            apiEndPoints.getUserToken(userCookieToken),
            );
            await setBearerToken(response.data.token);
            setTokenLoaded(response.data.token);
            if(!metaVersion){
              localStorage.setItem('appVersion', packageData.version);
            }

            try {
              const currentUserDataForZippy = await api.get(
                apiEndPoints.getProfileDetail(),
              );  
              if(currentUserDataForZippy.data.user.email != "cole@parcelpath.com"){
                zipy.init('9fc4c33c',{releaseVer: "1.1.1", rootDomain: "parcelpath.com"});
                // zipy integration
                zipy.identify(currentUserDataForZippy.data.user.email, {
                  firstName: currentUserDataForZippy.data.user.first_name,
                  lastName: currentUserDataForZippy.data.user.last_name,
                  email: currentUserDataForZippy.data.user.email,
                  customerName: currentUserDataForZippy.data.user.email
                });
              }
              } catch (error) {
              }

            dispatch(getWalletCurrentBalance())
          } catch (error) {
            localStorage.removeItem("TOKEN");
            localStorage.removeItem("BEARER_TOKEN");
            console.log("Not Authorized : Redirect to parcel path")
            sessionStorage.clear()
            window.location = `${process.env.REACT_APP_PARCEL_BASE_URL}/login?redirect=${window.location.href}`;
            return
          }
        })()
    } 
    else if(!pathName.includes(window.location.pathname) && !pathName.includes(window.location?.pathname?.split("/")[1])){
      localStorage.removeItem("TOKEN");
        localStorage.removeItem("BEARER_TOKEN");
        console.log("Not Authorized : Redirect to parcel path")
        sessionStorage.clear()
        window.location = `${process.env.REACT_APP_PARCEL_BASE_URL}/login?redirect=${window.location.href}`;
        return
    }else{
    //  if(process.env.APP_ENV =="production"){
        //zipy.init('9fc4c33c',{releaseVer: "1.1.1", rootDomain: "parcelpath.com"});

      //}

    }

  },[])
  return (
    <CacheBuster
      currentVersion={packageData.version}
      isEnabled={true}
      loadingComponent={<CircularLoader />}
    >
      {!pathName.includes(window.location.pathname) &&
      !pathName.includes(window.location?.pathname?.split("/")[1]) ? (
        isTokenLoaded && (
          <div className="App">
            <Box sx={{ display: "flex" }}>
              <MiniDrawer />
              <Box
                className="main-box"
                component="main"
                style={{ width: "80%" }}
                sx={{ flexGrow: 1, p: 3 }}
              >
                <DrawerHeader />
                <Routes />
              </Box>
            </Box>
            <RouteChangeTracker />
            <ToastContainer autoClose={false} />
          </div>
        )
      ) : (
        <div className="App">
          <RouteChangeTracker />
          <Routes />
          <ToastContainer autoClose={false} />
        </div>
      )}
    </CacheBuster>
  );
}

export default App;
