const initialState ={
    addressesTypes : null
}

const addAddressReducer = (state = initialState,action) => {
    switch (action.type) {
        case 'GET_ADDRESS_TYPES':
            return {
                ...state,
                addressesTypes: action.payload
            }
        default:
            return state
    }
}

export default addAddressReducer;