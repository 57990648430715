import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getTransistData } from "../Quote/action";
import { postViewShipmentsData } from "./action";
import CustomizedInputBase from "../../components/Input";
import { useMediaQuery } from "@mui/material";

export default function AddressShipmentHooks() {
  const userData = useSelector((state) => state);
  const [loadingData, setLoadingData] = useState(true);
  const params = useParams();
  const matches = useMediaQuery('(max-width:900px)');
  const radiusMatches = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const viewShipmentDetails = userData?.VieShipmentsDataReducer?.ShipmentsDetails?.shipment;
  const [originAddress, setOriginAddress] = React.useState({
    addressLine1: viewShipmentDetails?.origin?.address1,
    originName: viewShipmentDetails?.origin?.nick_name,
    addressLine2: viewShipmentDetails?.origin?.address2,
    addressLine3:viewShipmentDetails?.origin?.city +", " +viewShipmentDetails?.origin?.state +", " +viewShipmentDetails?.origin?.zipcode,
    pickupStartTime: viewShipmentDetails?.origin?.available_from,
    pickupEndTime: viewShipmentDetails?.origin?.available_to,
    coordinator: viewShipmentDetails?.origin?.contact_name,
    email: viewShipmentDetails?.origin?.contact_email,
    state: viewShipmentDetails?.origin?.state,
    LocationType: viewShipmentDetails?.origin?.location_type,
    pickupArea: viewShipmentDetails?.origin?.pickup_area,
    dialCode: viewShipmentDetails?.origin?.phone,
    ext: viewShipmentDetails?.origin?.phone_ext,
  });
  const [destinationAddress, setDestinationAddress] = React.useState({
    destinationAddressLine1: viewShipmentDetails?.destination?.address1,
    destinationName: viewShipmentDetails?.destination?.nick_name,
    destinationAddressLine2: viewShipmentDetails?.destination?.address2,
    destinationAddressLine3: viewShipmentDetails?.destination?.city +", " +viewShipmentDetails?.destination?.state +", " +viewShipmentDetails?.destination?.zipcode,
    destinationStartTime: viewShipmentDetails?.destination?.available_from,
    destinationEndTime: viewShipmentDetails?.destination?.available_to,
    destinationCoordinator: viewShipmentDetails?.destination?.contact_name,
    destinationEmail: viewShipmentDetails?.destination?.contact_email,
    destinationState: viewShipmentDetails?.destination?.state,
    destinationLocationType: viewShipmentDetails?.destination?.location_type,
    destinationPickupArea: viewShipmentDetails?.destination?.pickup_area,
    destinationDialCode: viewShipmentDetails?.destination?.phone,
    dest_contact_phone_ext: viewShipmentDetails?.destination?.phone_ext,
  });
  const [pickUpTimeCheck, setPickUpTimeCheck] = React.useState(false);
  const [deliveryTimeCheck, setDeliveryTimeCheck] = React.useState(false);
  const [dialogData, setDialogData] = useState();
  const [open, setOpen] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (viewShipmentDetails)
      setOriginAddress({
        addressLine1: viewShipmentDetails?.origin?.address1,
        originName: viewShipmentDetails?.origin?.nick_name,
        addressLine2: viewShipmentDetails?.origin?.address2,
        addressLine3: viewShipmentDetails?.origin?.city +", " +viewShipmentDetails?.origin?.state +", " +viewShipmentDetails?.origin?.zipcode,
        pickupStartTime: viewShipmentDetails?.origin?.available_from,
        pickupEndTime: viewShipmentDetails?.origin?.available_to,
        coordinator: viewShipmentDetails?.origin?.contact_name,
        email: viewShipmentDetails?.origin?.contact_email,
        state: viewShipmentDetails?.origin?.state,
        LocationType: viewShipmentDetails?.origin?.location_type,
        pickupArea: viewShipmentDetails?.origin?.pickup_area,
        dialCode: viewShipmentDetails?.origin?.phone,
        ext: viewShipmentDetails?.origin?.phone_ext,
      });
    setDestinationAddress({
      destinationAddressLine1: viewShipmentDetails?.destination?.address1,
      destinationName: viewShipmentDetails?.destination?.nick_name,
      destinationAddressLine2: viewShipmentDetails?.destination?.address2,
      destinationAddressLine3:viewShipmentDetails?.destination?.city +", " +viewShipmentDetails?.destination?.state +", " +viewShipmentDetails?.destination?.zipcode,
      destinationStartTime: viewShipmentDetails?.destination?.available_from,
      destinationEndTime: viewShipmentDetails?.destination?.available_to,
      destinationCoordinator: viewShipmentDetails?.destination?.contact_name,
      destinationEmail: viewShipmentDetails?.destination?.contact_email,
      destinationState: viewShipmentDetails?.destination?.state,
      destinationLocationType: viewShipmentDetails?.destination?.location_type,
      destinationPickupArea: viewShipmentDetails?.destination?.pickup_area,
      destinationDialCode: viewShipmentDetails?.destination?.phone,
      dest_contact_phone_ext: viewShipmentDetails?.destination?.phone_ext,
    });
  }, [viewShipmentDetails]);

  useEffect(() => {
    (async () => {
      setLoadingData(true);
      if (params?.id) {
        await dispatch(postViewShipmentsData({ shipment_id: params?.id }));
      } else {
        navigate(`/`);
      }
      setLoadingData(false);
    })();
  }, []);
  
  const handleTransist = () => {
    dispatch(
      getTransistData(
        {
          shipment_id: params?.id,
          scac: viewShipmentDetails?.carrier_scac,
        },
        setDialogData
      )
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkHandleChanges = () => {
    setPickUpTimeCheck(!pickUpTimeCheck);
  };

  const checkDeliveryHandleChanges = () => {
    setDeliveryTimeCheck(!deliveryTimeCheck);
  };

  const handleChangeInput = (key, value) => {
    if (
      key === "destinationAddressLine1" ||
      key === "destinationAddressLine2" ||
      key === "destinationAddressLine3" ||
      key === "destinationStartTime" ||
      key === "destinationEndTime" ||
      key === "destinationCoordinator" ||
      key === "destinationEmail" ||
      key === "destinationDialCode"
    ) {
      if (key === "destinationAddressLine1") {
        setDestinationAddress({
          ...destinationAddress,
          [key]: value.slice(0, 25),
        });
      } else {
        setDestinationAddress({
          ...destinationAddress,
          [key]: value,
        });
      }
    } else {
      if (key === "addressLine1") {
        setDestinationAddress({
          ...destinationAddress,
          [key]: value.slice(0, 25),
        });
      }
      setOriginAddress({
        ...originAddress,
        [key]: value,
      });
    }
  };

  const renderInput = (
    type = "text",
    id,
    label,
    value,
    endIcon,
    labelFontSize
  ) => {
    return (
      <div>
        <CustomizedInputBase
          id={id}
          label={label}
          value={value}
          type={type}
          onChange={handleChangeInput}
          endIcon={endIcon}
          labelFontSize={labelFontSize}
          readOnly={true}
        />
      </div>
    );
  };

  const density = (weight, height, width, length, unit) => {
    const weightt = Number(weight);
    const handlingUnit = Number(unit);
    const cubicFeet = (Number(height) * Number(width) * Number(length)) / 1728;
    const Density = (weightt / (cubicFeet * handlingUnit)).toFixed(2);
    return Density;
  };

  return {
    originAddress,
    destinationAddress,
    viewShipmentDetails,
    open,
    setOpen,
    dialogData,
    handleTransist,
    loadingData,
    density,
    renderInput,
    handleClose,
    params,
    checkDeliveryHandleChanges,
    checkHandleChanges,
    handleChangeInput,
    matches,
    radiusMatches
  };
}
